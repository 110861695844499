import React, { useCallback, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  VStack,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { WriterService } from "../../services/writerService";
import { debounce } from "lodash";
import { formatDate } from "../../utils/dateTimeConversion";
import { useNavigate } from "react-router-dom";

interface SearchResult {
  id: string;
  label: string;
  updatedAt: string;
}

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const [filteredPaperResults, setFilteredPaperResults] = useState<SearchResult[]>([]);
  const [filteredOutlineResults, setFilteredOutlineResults] = useState<SearchResult[]>([]);
  const navigate = useNavigate();

  const fetchResults = async (value: string) => {
    if (value) {
      const results = await WriterService.search(value);
      // const arrayResults = [...results.outlines, ...results.papers];  

      const mappedPaperResults = results.papers.map((result) => ({
        id: result.id,
        label: result.name,
        updatedAt: result.updatedAt
      }));

      const mappedOutlineResults = results.outlines.map((result) => ({
        id: result.id,
        label: result.name,
        updatedAt: result.updatedAt
      }));

      setFilteredPaperResults(mappedPaperResults);
      setFilteredOutlineResults(mappedOutlineResults);
    } else {
      setFilteredPaperResults([]);
      setFilteredOutlineResults([]);
    }
  };

  const debouncedFetchResults = useCallback(debounce(fetchResults, 300), []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);
    debouncedFetchResults(value);
  };

  const handelSearchedDocumentOnClick = (id: string) => {
    navigate(`/editor/${id}`)
  }

  return (
    <VStack align="start" spacing={2} w="full" p={5} pos={'relative'}>
      <InputGroup bg={'white'} borderRadius={'25px'} zIndex={99}>
        <Input
          placeholder="Search Document or Template"
          value={query}
          onChange={handleSearch}
          variant="outline"
          borderRadius={'25px'}
        />
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="#009cf0" />
        </InputLeftElement>
      </InputGroup>

      {(filteredPaperResults.length > 0 || filteredOutlineResults.length > 0) && (
        <Box
          w="calc(100% - 40px)"
          border="1px"
          borderColor="gray.200"
          borderRadius={'0 0 15px 15px'}
          boxShadow="md"
          maxH="200px"
          overflowY="auto"
          bgColor="#F1F5F9"
          p={2}
          mt={'30px'}
          pt={'20px'}
          pos={'absolute'}
          zIndex={98}
        >
          <List spacing={2}>
            {filteredPaperResults.length > 0 &&
              <>
                <Heading pl={2} pt={2} size={'sm'} color={'#8e9abc'}>Documents</Heading>
                {filteredPaperResults.map((result) => (
                  <ListItem
                    key={result.id}
                    px={5}
                    py={1}
                    borderRadius={'5px'}
                    _hover={{ bg: "rgba(56, 152, 255, 0.38)", cursor: "pointer" }}
                    onClick={() => handelSearchedDocumentOnClick(result.id)}
                  >
                    <Flex flexDir='row' justifyContent={'space-between'}>
                      <Box>{result.label}</Box>
                    </Flex>
                  </ListItem>
                ))}
              </>
            }

            {filteredOutlineResults.length > 0 &&
              <>
                <Heading borderTop={ filteredPaperResults.length !== 0 ? '1px solid #d3d7e2' : null} pl={4} pt={3} size={'sm'} color={'#8e9abc'}>Templates 123</Heading>
                {filteredOutlineResults.map((result) => (
                  <ListItem
                    key={result.id}
                    px={4}
                    py={2}
                    borderRadius={'20px'}
                    _hover={{ bg: "blue.100", cursor: "pointer" }}
                  >
                    <Flex flexDir='row' justifyContent={'space-between'}>
                      <Box>{result.label}</Box>
                      <Box>{formatDate(result.updatedAt, "MM/dd/yyyy")}</Box>
                    </Flex>
                  </ListItem>
                ))}
              </>
            }

            {(filteredPaperResults.length == 0 && filteredOutlineResults.length == 0) &&
              <ListItem
                px={5}
                py={1}
                borderRadius={'5px'}
              >
                <Flex flexDir='row' justifyContent={'space-between'}>
                  <Box>No results</Box>
                </Flex>
              </ListItem>
            }
          </List>
        </Box>
      )}
    </VStack>
  );
};

export default SearchBar;
