import { Box, Heading, Flex, Button, VStack, FormControl, FormLabel, Input, Image, Textarea, Text } from '@chakra-ui/react';
import React, { LegacyRef } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import DonebaSpinner from '../DoenbaSpinner/DoenbaSpinner';
import { CreationIntent } from './PaperCreationWizard';
import { PaperOutline } from '../../types/Writer/OutlineNode';

type DraftUploadCardProps = {
    intent: CreationIntent;
    handleFileUploadInputElementOnClick: () => void;
    draftOutline: PaperOutline;
    isDraftUploading: boolean;
    inputDraftRef: LegacyRef<HTMLInputElement> | null;
    handleDraftUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    paperTitle: string;
    paperTopic: string;
    setPaperTitle: (title: string) => void;
    setPaperTopic: (topic: string) => void;
}

const DraftUploadCard: React.FC<DraftUploadCardProps> = ({
    intent,
    handleFileUploadInputElementOnClick,
    draftOutline,
    isDraftUploading,
    inputDraftRef,
    handleDraftUpload,
    paperTitle,
    paperTopic,
    setPaperTitle,
    setPaperTopic
}) => {
    return (
    <Box h={'350px'}>
            <Heading size={'md'} mb={1}>Upload Draft</Heading>
            <Text color={'gray.400'} mt={5}>Upload your draft and we will refine and modify your structure for you. It can also be uploaded in sections when you go to the writing page.</Text>
            <Flex flexDir={'row'} mt={4}>
                {intent !== CreationIntent.NO_DRAFT &&
                    <Box w={'160px'} mr={8}>
                        <Box
                            as="button"
                            onClick={handleFileUploadInputElementOnClick}
                            p={5}
                            border="1px solid"
                            borderRadius="20px"
                            borderColor="gray.300"
                            textAlign="center"
                            cursor="pointer"
                            w={'160px'}
                            h={'199px'}
                            mb={5}
                            mt={2}
                        >
                            {!draftOutline && !isDraftUploading &&
                                <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                                    <Box w={'40px'} mb={3}>
                                        <Image
                                            w={'40px'}
                                            objectFit='cover'
                                            src='/editor/export.png'
                                            alt='Doenba'
                                        />
                                    </Box>
                                    <Text color={'gray.500'}>Drag to Upload</Text>
                                    <Text mt={3} fontSize={12} color={'gray.400'} lineHeight={'12px'}>Please upload pdf. words. txt. files</Text>
                                    <Button size={'sm'} w='80%' mt='4' colorScheme={'brand'}>Select</Button>
                                </Flex>
                            }
                            {isDraftUploading &&
                                <Flex flexDir={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}>
                                    <DonebaSpinner />
                                    <Text mt={5} color={'gray.400'}>File Uploading</Text>
                                    <Button size={'sm'} w='80%' mt='4' color='#1d8aff' borderColor={'#1d8aff'} variant={'outline'}>Select</Button>
                                </Flex>
                            }
                            {draftOutline &&
                                <Flex flexDir={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}>
                                    <FaCheckCircle color='green' fontSize={'35px'} />
                                    <Text mt={5} color={'gray.400'}>File Uploading</Text>
                                    <Button size={'sm'} w='80%' mt='4' color='#1d8aff' borderColor={'#1d8aff'} variant={'outline'}>Select</Button>
                                </Flex>
                            }
                            <input
                                ref={inputDraftRef}
                                type="file"
                                
                                onChange={handleDraftUpload}
                                style={{ display: "none" }}
                            />
                        </Box>
                    </Box>
                }
                <Box w={'full'}>
                    <VStack spacing={3} align="start">
                        <FormControl>
                            <FormLabel>What is your article title?</FormLabel>
                            <Input
                                type="text"
                                bg={'white'}
                                value={paperTitle}
                                borderRadius={'12px'}
                                onChange={(e) => setPaperTitle(e.target.value)}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>
                                <Flex flexDir={'row'} alignItems={'center'}>
                                    What is your writing topic? <Text ml={1} color='red'>*</Text>
                                </Flex>
                            </FormLabel>
                            <Textarea
                                rows={3}
                                bg={'white'}
                                value={paperTopic}
                                borderRadius={'12px'}
                                onChange={(e) => setPaperTopic(e.target.value)}
                            />
                        </FormControl>
                    </VStack>
                </Box>
            </Flex>
        </Box>
    )
}

export default DraftUploadCard;