export interface OutlineNode {
	id: number;
	name: string;
	children: Omit<OutlineNode, 'children'>[];
}

export enum OutlineNodeType {
	Section = "section",
	Paragraph = "paragraph",
	Figure = "figure",
	Equation = "equation",
	Table = "table",
}

export interface PaperOutlineNode {
	id: number;
	type: OutlineNodeType;

	// Will have an empty array for all but Section  
	children?: PaperOutlineNode[];

	// For Section only
	name?: string;
	level?: number;
	label?: string;

	// For Paragraph only
	content?: string;

	// For Figure, Equation, Table
	caption?: string;
}

export interface PaperOutline {
	nodes: PaperOutlineNode[];
	nextId: number;
}