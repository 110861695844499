// Writer.tsx
import { Box, Flex, Text, Wrap, WrapItem, Heading, Modal, Image, useDisclosure, Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast, Card, CardBody, Center, Container, HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import PaperCard from '../components/PaperCard/PaperCard';
import { useNavigate } from 'react-router-dom';
import {
    Outline
} from '../types/Writer/Outline';
import { BASE_URL, OutlineService } from '../services';
import { ClientError } from '../utils/clientError';
import { PaperService } from '../services/paperService';
import { useAuth0 } from "@auth0/auth0-react";
import { Paper, PaperToneContent } from '../types/Writer/Paper';
import { EditIcon } from '@chakra-ui/icons';
import PaperCreationWizard, { CreationIntent } from '../components/PaperEditorComponents/PaperCreationWizard';
import SideNav from '../components/NavBar/SideNav';
import {
    PaperOutline,
} from '../types/Writer/OutlineNode';
// import { MathJax } from 'better-react-mathjax';
// import { OutlineNode } from '../types/Writer/OutlineNode';
import SearchBar from '../components/SearchBar/SearchBar';
import { BibContent } from '../types/Writer/BibContent';
import LangSelectV2 from '../components/NavBar/LangSelectV2';
import UserMenu from '../components/NavBar/Components/UserMenu';
import TemplateService from '../services/TemplateService';
import PreviewModalPanel from '../components/PreviewModalPanel/PreviewModalPanel';
import DoenbaSelect from '../components/DoenbaSelect/DoenbaSelect';
import DocumentService from '../services/DocumentService';

interface DocumentWithThumbnail {
    thumbnailUrl?: string;
}

interface DocumentExtended extends Paper, DocumentWithThumbnail { }

const Writer: React.FC = () => {
    const { isOpen: _isOutlineOpen, onOpen: _onOutlineOpen, onClose: _onOutlineClose } = useDisclosure();
    const { isOpen: isPaperViewerOpen, onOpen: onPaperViewerOpen, onClose: onPaperViewerClose } = useDisclosure();
    const navigate = useNavigate();
    const [_outlines, setOutlines] = useState<Outline[]>([]);
    const [papers, setPapers] = useState<Paper[]>([]);
    const [_selectedOutline, _setSelectedOutline] = useState<Outline>(null);
    const [selectedPaper, setSelectedPaper] = useState<Paper>(null);
    const toast = useToast();
    const [_isExtractingOutline, _setIsExtractingOutline] = useState<boolean>(false);
    const { isOpen: isNewDocumentOpen, onOpen: onNewDocumentOpen, onClose: onNewDocumentClose } = useDisclosure();
    const [newDocumentStep, setNewDocumentStep] = useState<string>('init');
    const [paperCreationIntent, setPaperCreationIntent] = useState<CreationIntent>(CreationIntent.WELL_STRUCTURED);
    const [paperTitle, setPaperTitle] = useState<string>('');
    const [paperTopic, setPaperTopic] = useState<string>('');
    const { user, isAuthenticated } = useAuth0();
    const [sortOption, setSortOption] = useState<string>("modifiedDate");

    const[templateIndex, setTemplateIndex] = useState<number>(-1);
    const[templates, setTemplates] = useState<DocumentExtended[]>([]);
    const { isOpen: isOpenPreviewPanel, onOpen: onOpenPreviewPanel, onClose: onClosePreviewPanel } = useDisclosure();

    useEffect(() => {
        async function getOutlines() {
            try {
                const outlines = await OutlineService.getOutlines();
                setOutlines(outlines);
            } catch (error) {
                new ClientError(error).toast();
            }
        }
        getOutlines();
    }, [])

    useEffect(() => {
        async function getPapers() {
            try {
                const papers = await PaperService.getPapers();
                setPapers(papers);
            } catch (error) {
                new ClientError(error).toast();
            }
        }
        getPapers();
    }, [])

    useEffect(() => {
        async function getTemplates() {
            try {
                const newTemplates: DocumentExtended[] = await TemplateService.getAllTemplates();
                setTemplates(newTemplates);

                for (const template of newTemplates) {
                    if (!template.thumbnailS3Path) {
                        continue;
                    }

                    const thumbnail = await DocumentService.getThumbnailById(template.id).catch(() => null);
                    if (!thumbnail) {
                        continue;
                    }

                    template.thumbnailUrl = URL.createObjectURL(thumbnail);
                }
                setTemplates(newTemplates);
            } catch (error) {
                new ClientError(error).toast();
            }
        }

        getTemplates();
    }, [])

    const useTemplate = async (outline: PaperOutline, draft?: string, resources?: BibContent[], tone?: PaperToneContent ) => {
        console.log(
            outline,
            draft,
            resources,
            tone
        )
        try {
            const newPaper = await PaperService.createPaper({
                name: paperTitle ? paperTitle : 'Untitled',
                authors: user.user_metadata.first_name && user.user_metadata.last_name ? `${user.user_metadata.first_name} ${user.user_metadata.last_name}` : user.name,
                outline: outline,
                abstract: 'The abstract is a concise summary of your entire paper, providing a clear overview of your research problem, methodology, key findings, and conclusions. It typically consists of 150–250 words and should allow the reader to understand the essence of your research without needing to read the entire paper.',
                keywords: 'The keywords section lists the most important terms related to your research that help others find your paper through search engines or databases. Typically, you will include 4–6 keywords that are directly related to the core content of your research.',
                draft: draft ? draft : '',
                resources: resources ? resources : [],
                tone: tone ? tone : {} as PaperToneContent,
            });
            navigate(`/editor/${newPaper.id}`);
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    const continueEditing = (paper_id) => {
        console.log('continue to editing ', paper_id);
        navigate(`/editor/${paper_id}`);
    }

    const deletePaper = async (paperId) => {
        const originalPapers = papers;
        try {
            const newPapers = papers.filter(paper => paper.id !== paperId);
            setPapers(newPapers);
            await PaperService.deletePaper(paperId);
            toast({
                title: `Ppaer has been deleted.`,
                description: ``,
                status: "success",
                duration: 3000,
            })
        } catch (error) {
            new ClientError(error).toast();
            setPapers(originalPapers);
        }
    }

    const viewPaper = async (paperId: string) => {
        const filteredPaper = papers.find(paper => paper.id === paperId);
        setSelectedPaper(filteredPaper);
        onPaperViewerOpen();
    }

    const newDocumentOnClick = () => {
        onNewDocumentOpen();
        setNewDocumentStep('init');
    }

    const onNewDocumentOnClose = () => {
        onNewDocumentClose();
        setPaperTitle('');
        setPaperTopic('');
        setPaperCreationIntent(CreationIntent.WELL_STRUCTURED);
    }

    const onDraftFinish = ({ draft, resources, outline, tone }): void => {
        useTemplate(outline, draft, resources, tone);
    }

    const handleSortingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;
        setSortOption(selectedOption);

        const sortedPapers = [...papers].sort((a, b) => {
            if (selectedOption === "updatedAt") {
                return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
            } else if (selectedOption === "createdAt") {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            } else if (selectedOption === "name") {
                return a.name.localeCompare(b.name);
            }
            return 0;
        });

        setPapers(sortedPapers);
    };

    const intentOnClick = (intent: CreationIntent) => {
        setNewDocumentStep('startToCreate');
        setPaperCreationIntent(intent);
    }

    return (
        <Flex flexDir={'row'} w={'full'} h={'full'} className='main-bg'>
            <SideNav />
            <Flex flexDir={'column'} w={'full'} h={'full'}>
                <Flex flexDir={'row'} w={'full'} h={'90px'} justify="flex-end" align='center' px={5}>
                    <Box mr={'30px'}>
                        <LangSelectV2 />
                    </Box>
                    {isAuthenticated && user && (
                        <UserMenu user={user} />
                    )}
                </Flex>
                <Container minW={'75%'}>
                    <SearchBar />
                    <Flex flexDir={'column'} h={'full'} px={10} overflow={'auto'} w={'full'}>
                        <Box bgColor={'rgba(255, 255, 255, 0.6)'} borderRadius={'25px'} w={'full'} border={'1px solid white'}>
                            <Heading p={5} size={'md'} mb={3}>Start a new document</Heading>
                            <Wrap my={5} overflow={'auto'} w={'full'} p={5} pt={0} display={'flex'} justifyContent={papers.length <= 0 ? 'center' : 'flex-start'}>
                                <WrapItem display={'flex'} flexDir={'column'} px={6}>
                                    <Card w={'128px'} h={'157px'} cursor={'pointer'} _hover={{ boxShadow: '2px 2px 5px #d3d7e2' }} border={'1px solid #d3d7e2'}>
                                        <CardBody onClick={newDocumentOnClick}>
                                            <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                                                <Box>
                                                    <Image
                                                        w={'55px'}
                                                        objectFit='cover'
                                                        src='/doenba-ico-blue.png'
                                                        alt='Doenba'
                                                    />
                                                </Box>
                                            </Center>
                                        </CardBody>
                                    </Card>
                                    <Box mt={2} w={'full'} textAlign={'center'}>
                                        <Text fontSize={14}>
                                            Create
                                        </Text>
                                    </Box>
                                </WrapItem>
                                {papers.length > 0 && templates.map((template, i) => (
                                    <WrapItem key={template.id} display={'flex'} flexDir={'column'} px={6}>
                                        <Card w={'128px'} h={'157px'} cursor={'pointer'} _hover={{ boxShadow: '2px 2px 5px #d3d7e2' }} border={'1px solid #d3d7e2'}>
                                            <CardBody onClick={() => { setTemplateIndex(i); onOpenPreviewPanel() }}>
                                                <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                                                    <Image
                                                        w={'55px'}
                                                        objectFit='cover'
                                                        src={BASE_URL + template['snapshotS3Path']}
                                                        alt='template draft'
                                                    />
                                                </Center>
                                            </CardBody>
                                        </Card>
                                        <Box mt={2} w={'full'} textAlign={'center'}>
                                            <Text fontSize={14}>
                                                {template.name}
                                            </Text>
                                        </Box>
                                    </WrapItem>
                                ))}
                            </Wrap>
                            {papers.length <= 0 &&
                                <Wrap borderTop={'1px solid #d3d7e2'} my={5} overflow={'auto'} w={'full'} p={5}>
                                    <WrapItem>
                                        <HStack spacing='21px'>
                                            {templates.map((template, i) => (
                                                <Card key={template.id} w={'150px'} h={'200px'} cursor={'pointer'} _hover={{ boxShadow: '2px 2px 5px #d3d7e2' }} border={'1px solid #d3d7e2'}>
                                                    <CardBody onClick={() => { setTemplateIndex(i); onOpenPreviewPanel() }}>
                                                        <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                                                            <Image
                                                                w={'55px'}
                                                                objectFit='cover'
                                                                src={BASE_URL + template['snapshotS3Path']}
                                                                alt='template draft'
                                                            />
                                                        </Center>
                                                    </CardBody>
                                                    <Box mt={2} w={'full'} textAlign={'center'}>
                                                        <Text fontSize={14}>
                                                            {template.name}
                                                        </Text>
                                                    </Box>
                                                </Card>
                                            ))}
                                        </HStack>
                                    </WrapItem>
                                </Wrap>
                            }
                        </Box>

                        {papers.length > 0 &&
                            <Box mt={5} bgColor={'rgba(255, 255, 255, 0.6)'} p={5} borderRadius={'25px'} pb={12} w={'full'} border={'1px solid white'}>
                                <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} pb={3}>
                                    <Heading size={'md'} flex={'1 auto'}>Recent Documents</Heading>
                                    <DoenbaSelect
                                        options={[
                                            { label: 'Modified Date', value: 'updatedAt' },
                                            { label: 'Opened Date', value: 'createdAt' },
                                            { label: 'Title', value: 'name' },
                                        ]}
                                        value={sortOption}
                                        onChange={handleSortingChange}
                                        variant="outline"
                                        borderColor="gray.300"
                                        hoverBorderColor="gray.400"
                                    />
                                </Flex>
                                <Wrap mt={5} w={'full'} overflow={'auto'} h={'35vh'}>
                                    {papers.map(paper => (
                                        <WrapItem key={paper.id} px={4} pb={6}>
                                            <PaperCard
                                                paper={paper}
                                                viewPaper={() => viewPaper(paper.id)}
                                                continueEditing={() => continueEditing(paper.id)}
                                                discardPaper={() => deletePaper(paper.id)}
                                            />
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            </Box>
                        }
                    </Flex>
                </Container>
            </Flex>

            <Modal isOpen={isNewDocumentOpen} onClose={onNewDocumentOnClose} size={'lg'} closeOnOverlayClick={false} >
                <ModalOverlay bg={'whiteAlpha.700'} />
                <ModalContent minW={'730px'} bg={'#fbfcff'} top={'20%'}>
                    <ModalCloseButton />
                    <ModalBody p={8} bg={'#FBFCFF'}>
                        {newDocumentStep === 'init' &&
                            <Flex flexDir={'column'}>
                                <Heading size={'md'} mb={1}>New Document</Heading>
                                <Flex flexDir={'row'} w={'full'} mb={12} mt={12} justifyContent={'space-between'}>
                                    <Center bg={'white'} border={'1px solid #d3d7e2'} borderRadius={'12px'} mr={2} w={'200px'} cursor={'pointer'} h={'200px'} _hover={{ bg: 'rgba(56, 152, 255, 0.53)' }} onClick={() => intentOnClick(CreationIntent.WELL_STRUCTURED)} fontSize={'14px'}>
                                        <Flex flexDir={'column'} alignItems={'center'} >
                                            <Box mb={5}>
                                                <Image
                                                    w={'100px'}
                                                    objectFit='cover'
                                                    src='/editor/document-text.png'
                                                    alt='well strucutred draft'
                                                />
                                            </Box>
                                            I have a well structured draft
                                        </Flex>
                                    </Center>
                                    <Center bg={'white'} border={'1px solid #d3d7e2'} borderRadius={'12px'} mr={2} w={'200px'} cursor={'pointer'} h={'200px'} _hover={{ bg: 'rgba(56, 152, 255, 0.53)' }} onClick={() => intentOnClick(CreationIntent.NORMAL_DRAFT)} fontSize={'14px'}>
                                        <Flex flexDir={'column'} alignItems={'center'}>
                                            <Box mb={5}>
                                                <Image
                                                    w={'100px'}
                                                    objectFit='cover'
                                                    src='/editor/document.png'
                                                    alt='normal draft'
                                                />
                                            </Box>
                                            I have a part of draft
                                        </Flex>
                                    </Center>
                                    <Center bg={'white'} border={'1px solid #d3d7e2'} borderRadius={'12px'} w={'200px'} cursor={'pointer'} h={'200px'} _hover={{ bg: 'rgba(56, 152, 255, 0.53)' }} onClick={() => intentOnClick(CreationIntent.NO_DRAFT)} fontSize={'14px'}>
                                        <Flex flexDir={'column'} alignItems={'center'} >
                                            <Box mb={5}>
                                                <Image
                                                    w={'100px'}
                                                    objectFit='cover'
                                                    src='/editor/edit.png'
                                                    alt='no draft'
                                                />
                                            </Box>
                                            I want to start with blank
                                        </Flex>
                                    </Center>
                                </Flex>
                            </Flex>
                        }
                        {newDocumentStep === 'startToCreate' &&
                            <PaperCreationWizard
                                intent={paperCreationIntent}
                                onDraftFinish={onDraftFinish}
                                backToInit={() => setNewDocumentStep('init')}
                                paperTitle={paperTitle}
                                paperTopic={paperTopic}
                                setPaperTitle={setPaperTitle}
                                setPaperTopic={setPaperTopic}
                            />
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
            {selectedPaper &&
                <Modal isOpen={isPaperViewerOpen} onClose={onPaperViewerClose} size={'full'}>
                    <ModalOverlay />
                    <ModalContent w={'90%'} h={'90vh'}>
                        <ModalHeader>{selectedPaper.name}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody overflow={'auto'}>
                            {JSON.stringify(selectedPaper.content)}
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='yellow' size={'sm'} leftIcon={<EditIcon />} mr={3} onClick={() => continueEditing(selectedPaper.id)}>
                                Edit
                            </Button>
                            {/* <Button variant='ghost' size={'sm'} onClick={() => deletePaper(selectedPaper.id)}>Discard</Button> */}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }
            <Modal
                isOpen={isOpenPreviewPanel}
                onClose={onClosePreviewPanel}
                scrollBehavior="inside"
            >
                <ModalOverlay background={'whiteAlpha.200'} backdropFilter={'blur(2px)'} />
                <ModalContent minW="672px" minHeight="880px" top="-30px">
                    <ModalCloseButton />
                    <ModalBody p={5}>
                        <PreviewModalPanel templateIndex={templateIndex} templates={templates} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default Writer;
