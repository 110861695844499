import { HamburgerIcon } from '@chakra-ui/icons';
import { Box, Checkbox, Flex, Image, IconButton, Menu, MenuButton, MenuItem, MenuList, Tag } from '@chakra-ui/react';
import React from 'react';

export type CitationItemRowType = {
    id: string,
    label: string,
    count: number
}

type CitationItemRowProps = {
    resource: CitationItemRowType
}

const CitationItemRow: React.FC<CitationItemRowProps> = ({ resource }) => {
    return (
        <Flex flexDir={'row'} justifyContent={'space-between'} key={resource.label} py={2}>
            <Box>
                <Checkbox style={{ textDecoration: 'underline' }} onChange={() => {}} mr={2}>
                    {resource.label}
                </Checkbox>
                <Tag size={'sm'} variant={'outline'} colorScheme='brand'>{resource.count}</Tag>
            </Box>
            <Box>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon />}
                        variant='outline'
                        size={'xs'}
                    />
                    <MenuList w={'113px'}>
                        <MenuItem icon={
                            <Image w="12px" src="/editor/citation_rename.svg" alt="Refer button" />
                        }>
                            Rename
                        </MenuItem>
                        <MenuItem icon={
                            <Image w="12px" src="/editor/citation_delete.svg" alt="Refer button" />
                        }>
                            Delete
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        </Flex>
    )
}

export default CitationItemRow;