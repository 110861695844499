
import { OutlineNodeType, PaperOutlineNode } from "./OutlineNode";

const getTreeNodesToList = (
  data: PaperOutlineNode[],
  filter?: OutlineNodeType | undefined
): PaperOutlineNode[] => {
  if(!data) return
  let nodeList: PaperOutlineNode[] = [];
  for (let i = 0; i < data?.length; i++) {
    const node = data[i];
    if (filter == undefined || node?.type === filter) {
      nodeList.push(node);
    }
    if (node?.children?.length > 0) {
      nodeList = nodeList.concat(getTreeNodesToList(node.children, filter));
    }
  }
  return nodeList;
};

const getNodeRootInList = (
  data: PaperOutlineNode[],
  node: PaperOutlineNode
): PaperOutlineNode => {
  if(!data || !node) return 
  for (let i = 0; i < data.length; i++) {
    if (data[i].id == node.id) {
      return data[i];
    }
    const nodeFind = getNodeRootInList(data[i].children, node)
    if(nodeFind)
    {
      return data[i].level == 1? data[i]:nodeFind
    }
  }
  return undefined;
};

const formatString = (template: string, ...args: any[]): string => {
  return template.replace(/{(\d+)}/g, (match, index) => {
    return typeof args[index] !== "undefined" ? args[index] : match;
  });
};
function alignStringLen(str: string, targetLength: number): string {
  if (str.length === targetLength) {
    return str;
  }
  if (str.length < targetLength) {
    const spaceCount = targetLength - str.length;
    const leftSpaceCount = Math.floor(spaceCount / 2);
    const rightSpaceCount = spaceCount - leftSpaceCount;
    return " ".repeat(leftSpaceCount) + str + " ".repeat(rightSpaceCount);
  }
  return str.slice(0, targetLength - 3) + "...";
}

interface DragInfo {
  rectTop: number;
  rectButton: number;
}

export {
  getTreeNodesToList as getTreeNodes,
  formatString,
  getNodeRootInList as getNodeRoot,
  alignStringLen,
};
export type { DragInfo };
