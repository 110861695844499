import { Box, Button, Image, Checkbox, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';

enum REFERENCE_TYPE {
    FIGURE = 'Figure',
    TABLE = 'Table',
    EQUATION = 'Equation',
    SECTION = 'Section',
}

type CrossReferencePanelProps = {

}

const CrossReferencePanel: React.FC<CrossReferencePanelProps> = () => {
    const [currentReferenceType, setCurrentReferenceType ] = useState<REFERENCE_TYPE>(REFERENCE_TYPE.FIGURE);
    const [selectedReferences, setSelectedReferences] = useState<number[]>([]);
 
    const dummyReferences = [
        { label: 'item 1', value: '1'},
        { label: 'item 2', value: '2'},
        { label: 'item 3', value: '3'},
        { label: 'item 4', value: '1'},
        { label: 'item 5', value: '2'},
        { label: 'item 6', value: '3'},
        { label: 'item 7', value: '1'},
        { label: 'item 8', value: '2'},
        { label: 'item 9', value: '3'},
        { label: 'item 10', value: '1'},
        { label: 'item 11', value: '2'},
        { label: 'item 12', value: '3'},
        { label: 'item 13', value: '1'},
        { label: 'item 14', value: '2'},
        { label: 'item 15', value: '3'},
        { label: 'item 16', value: '1'},
        { label: 'item 17', value: '2'},
        { label: 'item 18', value: '3'},
    ]

    const toggleSelection = (index: number) => {
        setSelectedReferences((prev) =>
            prev.includes(index)
                ? prev.filter((i) => i !== index)
                : [...prev, index]
        );
    };

    return (
        <Flex flexDir={'column'}>
            <Flex flexDir={'row'} pb={1}>
                { Object.entries(REFERENCE_TYPE).map(ref => (
                    <Button key={ref[0]} size={'xs'} onClick={() => setCurrentReferenceType(REFERENCE_TYPE[ref[0]])}
                        flex={1}
                        mr={1}
                        borderRadius={'10px'}
                        variant={ref[1] == currentReferenceType ? 'solid' : 'outline'}
                        bg={ref[1] == currentReferenceType ? 'rgba(56, 152, 255, 0.38)' : null}
                    >{ref[1]}</Button>
                ))}
            </Flex>
            <Flex flexDir={'column'} overflow={'scroll'} h={'125px'} css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#9EC3E8",
                      borderRadius: "2px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#2b6cb0",
                    }
                  }}>
                {dummyReferences.map((ref, index) => (
                    <Flex key={ref.label} py={2}>
                        <Checkbox 
                            style={{ textDecoration: 'underline'}}
                            isChecked={selectedReferences.includes(index)}
                            onChange={() => toggleSelection(index)}
                        >{`item ${index}`}</Checkbox>
                    </Flex>
                ))}
            </Flex>
            <Flex flexDir={'row'} justifyContent={'space-between'} pt={1} pb={2}>
                <Box>
                    <Button w={'99px'} h={'30px'}
                        size={"sm"}
                        variant={'outline'}
                        borderColor={'#d3d7e2'}
                        leftIcon={
                            <Image w="12px" src="/editor/login.svg" alt="Refer button" />
                        }
                    >
                        Refer
                    </Button>
                </Box>
            </Flex>
        </Flex>
    )
}

export default CrossReferencePanel;