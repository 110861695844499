import { PaperOutline, PaperOutlineNode } from "../types/Writer/OutlineNode";

export const getNodeNames = (data: PaperOutline): string[] => {
    const names: string[] = [];

    function traverse(nodes: PaperOutlineNode[] | null[]) {
        nodes.forEach(node => {
            if (node && node.level == 1 && node.name) {
                names.push(node.name);
            }
            if (node && node.children) {
                traverse(node.children);
            }
        });
    }

    traverse(data.nodes);
    return names;
}
