import { Box, Heading, Flex, Button, Image, Text } from '@chakra-ui/react';
import React, { LegacyRef } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import DonebaSpinner from '../DoenbaSpinner/DoenbaSpinner';
import { PaperToneContent } from '../../types/Writer/Paper';

type ToneUploadCardProps = {
    handleFileUploadInputElementForToneOnClick: () => void;
    tone: PaperToneContent;
    isToneExtracting: boolean;
    inputToneRef: LegacyRef<HTMLInputElement> | null;
    handleArticleUploadForToneExtraction: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToneUploadCard: React.FC<ToneUploadCardProps> = ({
    handleFileUploadInputElementForToneOnClick,
    tone,
    isToneExtracting,
    inputToneRef,
    handleArticleUploadForToneExtraction
}) => {
    return (
        <Box h={'350px'}>
            <Heading size={'md'} mb={1}>Upload Tone Reference Article</Heading>
            <Text color={'gray'}>Upload your reference article. We will analyze it with you to learn from your reference. You can also upload it later in the writing panel.</Text>
            <Flex flexDir={'row'} mt={5} alignItems="center" justifyContent="center">
                <Box
                    as="button"
                    onClick={handleFileUploadInputElementForToneOnClick}
                    p={4}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="20px"
                    textAlign="center"
                    cursor="pointer"
                    w={'316px'}
                    h={'192px'}
                    mb={10}
                >
                    {!tone && !isToneExtracting &&
                        <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                            <Box w={'40px'} mb={3}>
                                <Image
                                    w={'40px'}
                                    objectFit='cover'
                                    src='/editor/export.png'
                                    alt='Doenba'
                                />
                            </Box>
                            <Text color={'gray.500'}>Drag to Upload</Text>
                            <Text fontSize={12} color={'gray.400'}>Please upload pdf. words. txt. files</Text>
                            <Button size={'sm'} bg='#1d8aff' w='40%' mt='4' color='white'>Select</Button>

                        </Flex>}
                    {isToneExtracting &&
                        <Flex flexDir={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}>
                            <DonebaSpinner />
                            <Text mt={5} color={'gray.400'}>File Uploading</Text>
                            <Button size={'sm'} w='40%' mt='4' color='#1d8aff' borderColor={'#1d8aff'} variant={'outline'}>Select</Button>
                        </Flex>
                    }
                    {tone && !isToneExtracting &&
                        <Flex flexDir={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}>
                            <FaCheckCircle color='green' fontSize={'35px'} />
                            <Text mt={5} color={'gray.400'}>File Uploading</Text>
                            <Button size={'sm'} w='80%' mt='4' color='#1d8aff' borderColor={'#1d8aff'} variant={'outline'}>Select</Button>
                        </Flex>
                    }
                    <input
                        ref={inputToneRef}
                        type="file"
                        onChange={handleArticleUploadForToneExtraction}
                        style={{ display: "none" }}
                    />
                </Box>
            </Flex>
        </Box>
    )
}

export default ToneUploadCard;