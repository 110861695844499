import React, { useRef, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { OutlineService } from '../../services';
import { PaperOutline } from '../../types/Writer/OutlineNode';
import { BibContent } from '../../types/Writer/BibContent';
import { PaperService } from '../../services/paperService';
import DraftUploadCard from './DraftUploadCard';
import ReferenceUploadCard from './ReferenceUploadCard';
import { PaperToneContent } from '../../types/Writer/Paper';
import ToneUploadCard from './ToneUploadCard';
import ResourcesUploadCard from './ResourcesUploadCard';
import OutlineSelectionPanel from './OutlineSelectionPanel';

const WizardModuleStep = Object.freeze({
    DRAFT_UPLOAD: 0,
    REFERENCE_UPLOAD: 1,
    TONE_UPLOAD: 2,
    OUTLINE_SELECTION: 3,
    RESOURCES_UPLOAD: 4
})

export enum CreationIntent {
    WELL_STRUCTURED = 'WELL_STRUCTURED',
    NORMAL_DRAFT = 'NORMAL_DRAFT',
    NO_DRAFT = 'NO_DRAFT'
}

export enum OutlineType {
    DRAFT_OUTLINE = 'DRAFT_OUTLINE',
    REFERENCE_OUTLINE = 'REFERENCE_OUTLINE'
}

type PaperCreationWizardProps = {
    intent: CreationIntent,
    onDraftFinish: ({
        resources,
        outline,
        tone
    }) => void;
    backToInit: () => void;
    paperTitle: string;
    paperTopic: string;
    setPaperTitle: (title: string) => void;
    setPaperTopic: (topic: string) => void;
}

const PaperCreationWizard: React.FC<PaperCreationWizardProps> = ({ intent, onDraftFinish, backToInit, paperTitle, paperTopic, setPaperTitle, setPaperTopic }) => {
    const [resources, setResources] = useState<BibContent[]>([]);
    const [moduleStep, setModuleStep] = useState<0 | 1 | 2 | 3 | 4>(WizardModuleStep.DRAFT_UPLOAD);
    const [isDraftUploading, setIsDraftUploading] = useState<boolean>(false);
    const [isReferenceUploading, setIsReferenceUploading] = useState<boolean>(false);
    const [isToneExtracting, setIsToneExtracting] = useState<boolean>(false);
    const [isResourcesUploading, setIsResourcesUploading] = useState<boolean>(false);
    const [draftOutline, setDraftOutline] = useState<PaperOutline>(null);
    const [referenceOutline, setReferenceOutline] = useState<PaperOutline>(null);
    const [tone, setTone] = useState<PaperToneContent | null>(null);
    const [pickedOutline, setPickedOutline] = useState<OutlineType | undefined>(undefined);

    const inputDraftRef = useRef<HTMLInputElement | null>(null);
    const inputReferenceRef = useRef<HTMLInputElement | null>(null);
    const inputToneRef = useRef<HTMLInputElement | null>(null);
    const inputResourcesRef = useRef<HTMLInputElement | null>(null);

    const handleFileUploadInputElementOnClick = () => {
        if (inputDraftRef.current) {
            inputDraftRef.current.click();
        }
    };

    const handleFileUploadInputElementForReferenceOnClick = () => {
        if (inputReferenceRef.current) {
            inputReferenceRef.current.click();
        }
    };

    const handleFileUploadInputElementForToneOnClick = () => {
        if (inputToneRef.current) {
            inputToneRef.current.click();
        }
    };

    const handleFileUploadInputElementForResourcesOnClick = () => {
        if (inputResourcesRef.current) {
            inputResourcesRef.current.click();
        }
    };

    const handleDraftUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setIsDraftUploading(true);
            OutlineService.extractOutline(file).then(
                res => {
                    setDraftOutline(res.outline);
                    setIsDraftUploading(false)
                }
            )
        } else {
            setIsDraftUploading(false);
            alert('Please upload a valid article file');
        }
    };

    const handleReferenceUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setIsReferenceUploading(true);
            OutlineService.extractOutline(file).then(
                res => {
                    setReferenceOutline(res.outline);
                    setIsReferenceUploading(false);
                }
            )
        } else {
            setIsReferenceUploading(false);
            alert('Please upload a valid article file');
        }
    };

    const handleArticleUploadForToneExtraction = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setIsToneExtracting(true);
            OutlineService.extractTone(file).then(
                res => {
                    setTone(res);
                    setIsToneExtracting(false);
                }
            )
        } else {
            setIsToneExtracting(false);
            alert('Please upload a valid article file');
        }
    };

    const handleResourcesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/plain') {
            setIsResourcesUploading(true);
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const content = e.target?.result;
                if (typeof content === 'string') {
                    PaperService.extractBibContent(content).then(
                        res => {
                            setResources(res);
                            setIsResourcesUploading(false)
                        }
                    )
                }
            };
            reader.readAsText(file);
        } else {
            alert('Please upload a valid .txt file');
        }
    };

    const goPrevious = () => {
        if (moduleStep > WizardModuleStep.DRAFT_UPLOAD) {
            setModuleStep((moduleStep - 1) as 0 | 1 | 2 | 3 | 4);
        } else if (moduleStep === WizardModuleStep.DRAFT_UPLOAD) {
            backToInit();
        }
    };


    const goNext = () => {
        if (!isDraftUploading || !isReferenceUploading || !isToneExtracting || !isResourcesUploading) {
            setModuleStep((moduleStep + 1) as 0 | 1 | 2 | 3 | 4);
        }
    }

    const draftOnFinish = () => {
        console.log(tone);
        onDraftFinish({
            outline: pickedOutline === OutlineType.DRAFT_OUTLINE ? draftOutline : referenceOutline,
            resources: resources,
            tone: tone
        });
    }

    const outlineOnChoosing = (type: OutlineType) => {
        if (type == OutlineType.DRAFT_OUTLINE) {
            if (draftOutline) {
                setPickedOutline(type);
            } else {
                handleFileUploadInputElementOnClick()
            }
        } else {
            if (referenceOutline) {
                setPickedOutline(type);
            } else {
                handleFileUploadInputElementForReferenceOnClick()
            }
        }
    }

    const removeResource = (index: number) => {
        setResources(prevResources => {
            return prevResources.filter((_, i) => i !== index);
        });
    };

    return (
        <Box p={3} w={'695px'} h={'404px'}>
            {moduleStep === WizardModuleStep.DRAFT_UPLOAD &&
                <DraftUploadCard
                    intent={intent}
                    handleFileUploadInputElementOnClick={handleFileUploadInputElementOnClick}
                    draftOutline={draftOutline}
                    isDraftUploading={isDraftUploading}
                    inputDraftRef={inputDraftRef}
                    handleDraftUpload={handleDraftUpload}
                    paperTitle={paperTitle}
                    paperTopic={paperTopic}
                    setPaperTitle={setPaperTitle}
                    setPaperTopic={setPaperTopic}
                />
            }
            {moduleStep === WizardModuleStep.REFERENCE_UPLOAD &&
                <ReferenceUploadCard
                    handleFileUploadInputElementForReferenceOnClick={handleFileUploadInputElementForReferenceOnClick}
                    referenceOutline={referenceOutline}
                    isReferenceUploading={isReferenceUploading}
                    inputReferenceRef={inputReferenceRef}
                    handleReferenceUpload={handleReferenceUpload}
                />
            }
            {moduleStep === WizardModuleStep.TONE_UPLOAD &&
                <ToneUploadCard
                    handleFileUploadInputElementForToneOnClick={handleFileUploadInputElementForToneOnClick}
                    tone={tone}
                    isToneExtracting={isToneExtracting}
                    inputToneRef={inputToneRef}
                    handleArticleUploadForToneExtraction={handleArticleUploadForToneExtraction}
                />
            }
            {moduleStep === WizardModuleStep.OUTLINE_SELECTION &&
                <OutlineSelectionPanel
                    pickedOutline={pickedOutline}
                    draftOutline={draftOutline}
                    isDraftUploading={isDraftUploading}
                    referenceOutline={referenceOutline}
                    isReferenceUploading={isReferenceUploading}
                    inputDraftRef={inputDraftRef}
                    inputReferenceRef={inputReferenceRef}
                    handleDraftUpload={handleDraftUpload}
                    handleReferenceUpload={handleReferenceUpload}
                    outlineOnChoosing={outlineOnChoosing}
                />
            }
            {moduleStep === WizardModuleStep.RESOURCES_UPLOAD &&
                <ResourcesUploadCard
                    handleFileUploadInputElementForResourcesOnClick={handleFileUploadInputElementForResourcesOnClick}
                    resources={resources}
                    isResourcesUploading={isResourcesUploading}
                    inputResourcesRef={inputResourcesRef}
                    handleResourcesUpload={handleResourcesUpload}
                    removeResource={removeResource}
                />
            }
            <Flex flexDir={'row'} justifyContent={'space-between'}>
                <Button
                    isDisabled={isDraftUploading || isReferenceUploading || isToneExtracting || isResourcesUploading || (moduleStep === WizardModuleStep.DRAFT_UPLOAD && paperTopic === '')}
                    onClick={goPrevious}
                    size={'lg'}
                    w={'139px'}
                    bg={'#9399AB'}
                    color={'white'}
                    borderRadius={'15px'}>
                    Back
                </Button>
                {(moduleStep === WizardModuleStep.DRAFT_UPLOAD || moduleStep === WizardModuleStep.REFERENCE_UPLOAD || moduleStep === WizardModuleStep.TONE_UPLOAD || moduleStep === WizardModuleStep.OUTLINE_SELECTION) &&
                    <Button
                        colorScheme='brand'
                        onClick={goNext}
                        isDisabled={isDraftUploading || isReferenceUploading || isToneExtracting || isResourcesUploading || (moduleStep === WizardModuleStep.DRAFT_UPLOAD && paperTopic === '')}
                        size={'lg'}
                        variant={(moduleStep === WizardModuleStep.DRAFT_UPLOAD && paperTopic === '') ? 'outline' : 'solid'}
                        w={'139px'}
                        borderRadius={'15px'}>
                        Next
                    </Button>
                }
                {moduleStep === WizardModuleStep.RESOURCES_UPLOAD &&
                    <Button colorScheme='brand' isDisabled={isResourcesUploading} size={'lg'} onClick={draftOnFinish} w={'139px'} borderRadius={'15px'}>Start</Button>
                }
            </Flex>
        </Box >
    );
};

export default PaperCreationWizard;
