import { chakra } from "@chakra-ui/react";
import { Function1 } from "lodash"
import {   useRef } from "react"
import TextareaAutosize from 'react-textarea-autosize';

const ChakraTextareaAutosize = chakra(TextareaAutosize);
const EditorContent: React.FC<{
  content: string
  updateNode:Function1<string, void>
}> = (props) => {
  const textareaRef = useRef(null);
  return (
  <ChakraTextareaAutosize 
    draggable
    defaultValue={props.content}
    ref={textareaRef}
    readOnly = {false}
    w={"100%"}
    minHeight={"10px"}
    onBlur={()=>{
      props.updateNode(textareaRef.current.value)
    }}
    fontFamily='"Times New Roman", Times, serif'
    resize="none"
    sx={{
      border: "none",
      outline: "none",
      boxShadow: "none",
    }}
    _focus={{
      border: "none",
      boxShadow: "none",
    }}
    _hover={{
      border: "none",
    }}
  />
)}
export default EditorContent