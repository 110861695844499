import axios from "axios";
import { BASE_URL } from ".";

class DocumentService {
    public static async getTemplateById(id: string): Promise<string> {
        const response = await axios.get(
          BASE_URL + "/v2/documents/" + id + "/snapshot"
        );
        return response.data;
    }

    public static async getThumbnailById(id: string) {
        const response = await axios.get<Blob>(BASE_URL + "/v2/documents/" + id + "/thumbnail", {
          responseType: "blob",
        });
        
        return response.data;
    }
}

export default DocumentService;