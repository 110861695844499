import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import CitationItemRow, { CitationItemRowType } from './CitationItemRow';

type CitationPanelProps = {

}

const CitationPanel:React.FC<CitationPanelProps> = () => {
    const dummyReferences: CitationItemRowType[] = [
        { id: '1', label: 'File1', count: 10},
        { id: '2', label: 'File2', count: 0},
        { id: '3', label: 'File3', count: 0},
        { id: '4', label: 'File4', count: 2},
    ]

    return (
        <Flex flexDir={'column'}>
            <Flex flexDir={'column'} overflow={'scroll'} h={'150px'} css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#9EC3E8",
                      borderRadius: "2px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#2b6cb0",
                    }
                  }}>
                {dummyReferences.map((ref) => (
                    <CitationItemRow key={ref.id} resource={ref} />
                ))}
            </Flex>
            <Flex flexDir={'row'} justifyContent={'space-between'} pt={1} pb={2}>
                <Box>
                    <Button w={'99px'} h={'30px'}
                        size={"xs"}
                        variant={'outline'}
                        leftIcon={
                            <FaQuoteLeft />
                        }
                    >
                        Cite
                    </Button>
                </Box>
                <Box>
                    <Button w={'99px'} h={'30px'}
                        size={"sm"}
                        variant={'solid'}
                        colorScheme='brand'
                        leftIcon={
                            <AddIcon />
                        }
                    >
                        Add
                    </Button>
                </Box>
            </Flex>
        </Flex>
    )
}

export default CitationPanel;