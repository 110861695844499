import { Box, Heading, Flex, Center } from '@chakra-ui/react';
import React, { LegacyRef } from 'react';
import { FaCheck } from 'react-icons/fa';
import DonebaSpinner from '../DoenbaSpinner/DoenbaSpinner';
import { OutlineType } from './PaperCreationWizard';
import { PaperOutline } from '../../types/Writer/OutlineNode';
import { getNodeNames } from '../../utils/flatternPaperNodes';

type OutlineSelectionPanelProps = {
    pickedOutline: OutlineType | undefined,
    draftOutline: PaperOutline,
    isDraftUploading: boolean,
    referenceOutline: PaperOutline,
    isReferenceUploading: boolean,
    inputDraftRef: LegacyRef<HTMLInputElement> | null;
    inputReferenceRef: LegacyRef<HTMLInputElement> | null;
    handleDraftUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleReferenceUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    outlineOnChoosing: (OutlineType) => void;
}

const OutlineSelectionPanel: React.FC<OutlineSelectionPanelProps> = ({
    pickedOutline,
    draftOutline,
    isDraftUploading,
    referenceOutline,
    isReferenceUploading,
    inputDraftRef,
    inputReferenceRef,
    handleDraftUpload,
    handleReferenceUpload,
    outlineOnChoosing
}) => {
    return (
        <Flex flexDir={'column'} alignItems={'center'} h={'350px'}>
            <Flex flexDir={'row'} justifyContent={'flex-start'} w={'full'}>
                <Heading size={'md'} mb={1}>Suggest Structure</Heading>
            </Flex>

            <Flex flexDir={'column'} w={'650px'} mt={3} mb={4} >
                <Flex flexDir={'row'} h={'274px'}>
                    <Flex flexDir={'column'} w={'50%'} borderRadius={'5px 0  0 5px'} border={'1px solid #ccc'} p={3} cursor={'pointer'} _hover={{ boxShadow: 'md' }} onClick={() => outlineOnChoosing(OutlineType.DRAFT_OUTLINE)} bg={pickedOutline === OutlineType.DRAFT_OUTLINE ? '#dfedff' : ''}>
                        <Flex flexDir={'row'} alignContent={'center'} justifyContent={'center'} alignItems={'center'} mb={5}>
                            {pickedOutline === OutlineType.DRAFT_OUTLINE && <FaCheck color='#1d8aff' />}
                            <Heading size='sm' ml={2} textAlign={'center'}>Your Structure</Heading>
                        </Flex>
                        <Box mt={3} overflow={'auto'} h='full'>
                            {draftOutline && getNodeNames(structuredClone(draftOutline)).map(node => (
                                <Box p={2} bg={'#c7dcff'} mb={2} borderRadius={'10px'} textAlign={'center'}>{node}</Box>
                            ))}
                            {!draftOutline && !isDraftUploading &&
                                <Center w='full' h={'full'} color={'gray'} fontSize={'14px'}>
                                    <Box w={'70%'} >
                                        Upload your draft to see your draft structure.
                                    </Box>
                                </Center>
                            }
                            {!draftOutline && isDraftUploading &&
                                <Center w='full' h={'full'} color={'gray'} fontSize={'14px'}>
                                    <DonebaSpinner />
                                </Center>
                            }
                        </Box>
                    </Flex>
                    <Flex flexDir={'column'} textAlign={'center'} w={'50%'} borderRadius={'0 5px 5px 0'} border={'1px solid #ccc'} p={3} cursor={'pointer'} _hover={{ boxShadow: 'md' }} onClick={() => outlineOnChoosing(OutlineType.REFERENCE_OUTLINE)} bg={pickedOutline === OutlineType.REFERENCE_OUTLINE ? '#dfedff' : ''}>
                        <Flex flexDir={'row'} alignContent={'center'} justifyContent={'center'} alignItems={'center'} mb={5}>
                            {pickedOutline === OutlineType.REFERENCE_OUTLINE && <FaCheck color='#1d8aff' />}
                            <Heading size='sm' ml={2} textAlign={'center'}>Suggest Structure</Heading>
                        </Flex>
                        <Box mt={3} overflow={'auto'} h={'full'}>
                            {referenceOutline && getNodeNames(structuredClone(referenceOutline)).map(node => (
                                <Box p={2} bg={'#c7dcff'} mb={2} borderRadius={'15px'} textAlign={'center'}>{node}</Box>
                            ))}
                            {!referenceOutline &&
                                <Center w='full' h={'full'} color={'gray'} fontSize={'14px'}>
                                    <Box w={'70%'} >
                                        Upload structure reference artilce to see suggest structure
                                    </Box>
                                </Center>
                            }
                            {!referenceOutline && isReferenceUploading &&
                                <Center w='full' h={'full'} color={'gray'} fontSize={'14px'}>
                                    <DonebaSpinner />
                                </Center>
                            }
                        </Box>
                    </Flex>
                    <input
                        ref={inputDraftRef}
                        type="file"
                        onChange={handleDraftUpload}
                        style={{ display: "none" }}
                    />
                    <input
                        ref={inputReferenceRef}
                        type="file"
                        onChange={handleReferenceUpload}
                        style={{ display: "none" }}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}

export default OutlineSelectionPanel;