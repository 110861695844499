import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    'paper-reading-title': 'Paper Studying',
                    'reader-title': 'Oracle Reader',
                    'trends-review-title': 'Trends & Reviews',
                    'paper-translator-title': 'Paper Translator',
                    'paper-reading-description': 'Paper reading description',
                    'trends-review-description': 'Trends and survey description',
                    'paper-translator-description': 'Paper translator description',
                    'summarizer-title': 'Doenba Summarizer',
                    'reviewer-title': 'Doenba Reviewer',
                    'taskqa-title': 'Task-oriented QA',
                    'translator-title': 'Paper Translator',
                    'summarizer-description': 'Doenba Summarizer produces a summary of an article or a topic within it, crafted as if written by a domain expert, tailored to user specifications',
                    'reviewer-description': 'Doenba Reviewer generates a hierarchically organized overview report from a large number of relevant articles.',
                    'taskqa-description': 'Doenba Task-Oriented QA enables users to ask any question about a paper or a collection of papers, whether specific to a topic or encompassing the entire content. Uwers can inquire about methods used, major results and findings, and case studies, and receive accurate and succinct answers.',
                    'translator-description': 'Doenba Paper Translator enables users to translate a paper written in their native language to English and vice versa while preserving the same text format.',
                    'explore-btn': 'Explore',
                    'my-library-title': 'My Library',
                    'my-library-drag-drop-description': 'Click to select or drag & drop PDF files.',
                    'select-a-file': 'Click to select or drag and drop a PDF file.',
                    'file-selected': 'File selected',
                    'file-format-label': 'It supports very long articles in the',
                    'upload-label': 'Upload article',
                    'search-placeholder': 'Search articles ...',
                    'mini-nav-taskrqa': 'Task RQA',
                    'mini-nav-taskrqa-description': 'Task Oriented Reading & QA',
                    'mini-nav-tasksearch': 'Task Search',
                    'mini-nav-tasksearch-description': 'Task Search',
                    'mini-nav-notes':'Notes',
                    'mini-nav-notes-description':'Add and edit notes',
                    'mini-nav-editor':'Editor',
                    'mini-nav-editor-description':'AI paper editor',
                    'quick-uploader-full-label': 'Click to upload or drag PDF here',
                    'quick-uploader-short-label': 'File uploader',
                    'oracle-reader-label': 'Enter a topic and press return to search for its contents.',
                    'my-plan-label': 'My Plan',
                    'my-profile-label': 'My Profile',
                    'global-getting-article-label': 'Getting your article ready at the moment.',
                    'study-sessions-label': 'Study sessions',
                    'all-study-sessions-label': 'All sessions',
                    'custom-summary-label': 'Custom summary',
                    'start-to-chat-label': 'Start',
                    'created-on-label': 'Created on',
                    'updated-at-label': 'Updated at',
                    'preview-label': 'Preview',
                    'download-label': 'Download',
                    'cut-label': 'Cut',
                    'remove-label': 'Remove',
                    'retry-indicator-label': 'RETRY',
                    'failure-indicator-label': 'UNSUPPORTED FILE ISSUES',
                    'in-progress-indicator-label': 'IN PROCESSING',
                    'action-label': 'Actions',
                    'article-title-label': 'Article Name',
                    'login-label': 'Login',
                    'logout-label': 'Logout',
                    'signup-label': 'Sign up',
                    'cancel-label': 'Cancel',
                    'is-cut-label': 'is cut',
                    'cancel-paste-label': 'Cancel paste',
                    'paste-here-label': 'Paste here',
                    'paste-successful-label': 'has been pasted successfully',
                    'logout-confirmation': 'Are you sure you want to sign out?',
                    'enable-inspiration-label': 'Enable Inspiration?',
                    'get-answer-label': 'Would you like to get the answer?',
                    'yes-label': 'Yes',
                    'no-label': 'No',
                    'supports-both-highlights': "Supports Topic Highlight and Task RQA Highlight",
                    'supports-topic-highlight': "Supports Topic Highlight",
                    'supports-task-rqa-highlight': "Supports Task RQA Highlight",
                    'supports-task-rqa': "Supports Task RQA",
                    'supports-custom-summary': "Supports Custom Summary",
                    'supports-figures': "Supports Figures",
                    'supports-tables': "Supports Tables",
                    'supports-equations': "Supports Equations",
                    'from-doenba-inspiration': "(Suggested by Doenba)",
                    'doenba-intro': 'Once upon a time, there was a Tibetan teacher named <b>Doenba</b> who had the remarkable ability to serve as the original author for any article, faithfully explaining its contents and providing inspirational suggestions. Our system replicates and expands upon Doenba\'s capabilities; hence, we have named it <b>Doenba</b>.',
                    'learn-more-btn': 'Learn more about Doenba',
                    'get-started-label': 'Get started',
                    'terms-of-use-label': 'Terms of Use',
                    'privacy-policy-label': 'Privacy Policy',
                    'title1': 'Reveal Authors\' Insights: Deep thoughts within reach.',
                    'title2': 'Summarize Smarter: Tailored to Meet Your Needs.',
                    'title3': 'Explore with AI: Your Questions, Your Interests, Appropriately Answered.',
                    'title4': 'Get Answers That Matter: No Hallucinations, No Distractions, No More Waste of Time.',
                    'description1': 'Discover the gateway to streamlined research, navigating through complex information and deep knowledge with ease.',
                    'description2': 'Experience the cutting-edge of summarization technology, delivering succinct and precise summaries tailored to your needs.',
                    'description3': 'Dive deeper into your interests with an AI-powered search function, connecting you with articles relevant to your interests.',
                    'description4': 'Engage with our specialized QA system, crafted to provide accurate answers by analyzing contents, ensuring that you receive informed responses to your inquiries.',
                    'identified-article-label': 'We have identified the same article in your library collection.',
                    'accept-label': 'Accept this article',
                    'continue-uploading-label': 'Upload as new',
                    'zoom-in': 'Zoom in',
                    'zoom-out': 'Zoom out',
                    "clear-highlights": "Clear highlights",
                    'focus-mode': 'Focus mode',
                    'remove-from': 'Remove from',
                    'add-to': 'Add to',
                    'my-reading-list': 'My reading list',
                    'my-reading-list-postfix': 'my reading list',
                    'math-explaination': 'Math f(x) explaination',
                    'support-center': 'Support center',
                    'get-summary-label': 'Get summary for this article',
                    'all-qa-tooltip': 'Initially, only the QA sessions related to the chosen article are visible. However, by enabling this feature, all sessions will become accessible.',
                    'generating-summary-label': 'Generating summary now.',
                    'middle-school': 'Middle school',
                    'high-school': 'High school',
                    'college': 'College',
                    'expert': 'Expert',
                    'others': 'Others',
                    'level': 'Level',
                    'words': 'words',
                    'generate-summary': 'Generate summary',
                    'summary-lang-label': 'English',
                    'rename': 'Rename',
                    'download': 'Download',
                    'delete': 'Delete',
                    'copy': 'Copy',
                    'highlight': 'Highlight',
                    'clear-highlight': 'Clear highlights',
                    'add-notes': 'Add to notes',
                    'coming-soon': 'coming soon',
                    'success-label': 'Success',
                    'failed-label': 'Failed',
                    'copy-successful': 'Copy successful',
                    'copy-failed': 'Copy failed',
                    'inspiration-message': 'Turning on the inspiration feature, Doenba will generate insightful suggestions based on your recent inquiries and the content of the article to encourage deeper and broader thinking.',
                    'enter-msg-here': 'Enter your questions here...',
                    'no-conversation-history': 'No history of conversation exists',
                    'search-articles-here': 'Search articles here...',
                    'remove-article-label': 'Remove artilce from reading list',
                    'no-article-been-added': 'No article been added.',
                    'processing-indicator-tooltip-label': 'The processing is in session for using Task RQA. We appreciate your patience.',
                    'processing-indicator-error': 'The system is currently busy. Please try again later.',
                    'open': 'Open',
                    'start-label': 'Start',
                    'read-later': 'Read later',
                    'is-ready-postfix': 'is ready',
                    'video-title-rqa': 'Reading and query-answering',
                    'video-title-inspiration': 'Inspiration mode',
                    'video-title-tables-formulas': 'Table and formulas analysis',
                    'video-title-organization': 'Data management',
                    'video-title-undergrad-math_demo': 'Undergrad Math Demo',
                    'demo-videos': 'Demo videos',
                    'topic-highlighting': 'Topic highlighting',
                    'highlighting-context': 'Highlights for related context',
                    'custom-summary-description': '',
                    'rqa-description': '',
                    'topic-highlighting-description': '',
                    'highlighting-context-description': '',
                    'inspiration-description': '',
                    'choose-existing-article': 'Choose existing article',
                    'case-study-label': 'Case study',
                    'in-action-label': 'Doenba.ai in Action',
                    'features-label': 'Features',
                    'delete-conversation-label': 'Delete this message',
                    'conversation-deleting-confirmation-message': 'Are you sure you want to delete this message from the conversation?',
                    'confirm-label': 'Confirmed',
                    'message-deleting-label': 'Deleting this message?',
                    'more-options-label': 'More options',
                    'quotation-label': 'Quote',
                    'editor-previewmodel-selectlabeltip':'Once you select the template, we will sync the structure to your writing',
                    'editor-previewmodel-selectbtnlabel':'select',
                    'editor-previewmodel-switchBtnTip':'there is no more template',
                    'editor-editormodel-leftHeader':'Reference Article',
                    'editor-editormodel-leftHeaderRefOutline':'Structure',
                    'editor-editormodel-leftHeaderRefTone':'Tone',
                    'editor-editormodel-leftUploadRef':'Upload  to replace your reference article. We will analyze it with you to merge from your reference about the structure.',
                    'editor-editormodel-leftDeleteRef':'Are you sure you want to delete this {0}?',
                    'editor-editormodel-leftDeletePanel1':'Are you sure you want to delete ',
                    'editor-editormodel-leftDeletePanel2':' in your structure?',
                    'editor-editormodel-leftDeletePanel3':'The content under this section will belongs to the Body.',
                    'editor-editormodel-leftBtnCancel':'Cancel',
                    'editor-editormodel-leftBtnDelete':'Delete',
                    'editor-editormodel-leftBtnConfirm':'Confirm',
                    'editor-editormodel-leftBtnSelect':'Selecte',
                    'editor-editormodel-leftBtnApply':'Apply',
                    'editor-editormodel-leftTipUpload':'Drag to Upload',
                    'editor-editormodel-leftTipUploadFile':' Please upload pdf. words. txt. files',
                    'editor-editormodel-leftOverview':'Overview',
                    'editor-editormodel-leftStyle':'Style',
                    'editor-editormodel-leftExport':'Export',
                    'editor-editormodel-leftTutorials':'Tutorials',
                    'editor-editormodel-leftHelps':'Helps',
                    'editor-editormodel-treeAdd':'Add New',
                    'editor-editormodel-treeRename':'Rename',
                    'editor-editormodel-treeDelete':'Delete',
                    'editor-editormodel-treeInputCancel':'Cancel',
                    'editor-editormodel-treeInputSave':'Save',
                    'editor-editormodel-noSectionTip1':'Doenba Editor’s goal is not only to assist you in writing but also to foster a collaborative environment and ensure an efficient, streamlined writing process.',
                    'editor-editormodel-noSectionTip2.1':'Click the',
                    'editor-editormodel-noSectionTip2.2':'to begin creating your structure, and you can start writing.',
                    'editor-editormodel-midNewSubsection2':'Heading2',
                    'editor-editormodel-midNewSubsection3':'Heading3',
                    'editor-editormodel-midNewSubsection4':'Heading4',
                    'editor-editormodel-midNewSubsection5':'Heading5',
                    'editor-editormodel-midNewSubsection6':'Heading6',
                    'editor-editormodel-midNewParagraph':'Paragraph',
                    'editor-editormodel-midNewTable':'Table',
                    'editor-editormodel-rightLibrary':'Resources Library',
                    'editor-editormodel-rightLibraryRemove':'Remove',
                    'editor-editormodel-rightLibraryCite':'Cite',
                    'editor-editormodel-rightLibraryAdd':'Add',
                }
            },
            cn: {
                translation: {
                    'paper-reading-title': 'Paper Studying',
                    'reader-title': 'Oracle Reader',
                    'trends-review-title': 'Trends & Reviews',
                    'paper-translator-title': 'Paper Translator',
                    'paper-reading-description': 'Paper reading description',
                    'trends-review-description': 'Trends and survey description',
                    'paper-translator-description': 'Paper translator description',
                    'summarizer-title': 'Doenba Summarizer',
                    'reviewer-title': 'Doenba Reviewer',
                    'taskqa-title': '任务导向问答 (Task-oriented QA)',
                    'translator-title': '论文翻译工具 (Paper Translator)',
                    'summarizer-description': 'Doenba Summarizer 根据用户定制的字数及其他要求，对文章或其中一个话题生成仿佛有领域专家撰写的精准摘要。',
                    'reviewer-description': 'Doenba Reviewer从大量相关文章中生成一个层次化组织的概述报告。',
                    'taskqa-description': 'Doenba任务导向问答（QA）允许用户提出关于一篇论文或一系列论文的任何问题，无论是特定于某一话题还是涵盖整个内容。比如询问所使用的方法、主要结果和发现以及案例研究，并获得准确而简洁的答案。',
                    'translator-description': 'Doenba论文翻译工具允许用户将他们的母语撰写的论文翻译成英文，反之亦然，同时保留相同的文本格式。',
                    'explore-btn': '使用',
                    'my-library-title': '我的文库',
                    'my-library-drag-drop-description': '点击选择或拖拽上传PDF文档。',
                    'select-a-file': '点击选择或拖拽上传PDF文档。',
                    'file-selected': '已上传的文档',
                    'file-format-label': '支持长文上传，格式包括：',
                    'upload-label': '上传',
                    'search-placeholder': '搜索文章 ...',
                    'mini-nav-taskrqa': '任务导向问答',
                    'mini-nav-taskrqa-description': '任务导向阅读与问答',
                    'mini-nav-tasksearch': '任务导向搜索',
                    'mini-nav-tasksearch-description': '任务导向搜索',
                    'mini-nav-notes':'Notes',
                    'mini-nav-notes-description':'Add and edit notes',
                    'mini-nav-editor':'写作助手',
                    'mini-nav-editor-description':'AI写作助手',
                    'quick-uploader-full-label': '点击选择或拖拽上传PDF文档',
                    'quick-uploader-short-label': '文档上传',
                    'oracle-reader-label': '给定一个主题，（敲击回车键）搜索相关内容',
                    'my-plan-label': '我的订阅',
                    'my-profile-label': '个人资料',
                    'global-getting-article-label': '正在导入文章',
                    'study-sessions-label': '询问',
                    'all-study-sessions-label': '全部问答',
                    'custom-summary-label': '定制摘要',
                    'start-to-chat-label': '开始',
                    'created-on-label': '上传日期',
                    'updated-at-label': '修改日期',
                    'preview-label': '预览',
                    'download-label': '下载',
                    'cut-label': '剪切',
                    'remove-label': '删除',
                    'retry-indicator-label': '重试',
                    'failure-indicator-label': '格式出现问题',
                    'in-progress-indicator-label': '文章正在处理',
                    'action-label': '选项',
                    'article-title-label': '文章名字',
                    'login-label': '登入',
                    'logout-label': '登出',
                    'signup-label': '注册',
                    'cancel-label': '取消',
                    'is-cut-label': '粘贴中',
                    'cancel-paste-label': '取消粘贴',
                    'paste-here-label': '粘贴于此',
                    'paste-successful-label': '被粘贴成功',
                    'logout-confirmation': '您确定是要退出登录么？',
                    'enable-inspiration-label': '开启启发模式？',
                    'get-answer-label': '您想问这个问题么?',
                    'yes-label': '是的',
                    'no-label': '不用',
                    "supports-both-highlights": "支持主题高亮和任务 RQA 高亮",
                    "supports-topic-highlight": "支持主题高亮",
                    "supports-task-rqa-highlight": "支持任务 RQA 高亮",
                    "supports-task-rqa": "支持任务 RQA",
                    "supports-custom-summary": "支持自定义摘要",
                    "supports-figures": "支持图表解析",
                    "supports-tables": "支持表格解析",
                    "supports-equations": "支持方程解析",
                    "from-doenba-inspiration": "（Doenba 提议）",
                    'doenba-intro': '<b>Doenba</b> 是很久以前的一名西藏智者，能力超凡，对任何从未读过的文章都能迅速和完整地理解其内容，并以原作者的角色忠实地解答关于文章内容的任意提问和提供启发式建议。我们的系统复制并扩展了<b>Doenba</b>的能力，故取名为<b>Doenba</b>。',
                    'learn-more-btn': '了解更多关于Doenba的信息',
                    'get-started-label': '开始使用',
                    'terms-of-use-label': '使用条款',
                    'privacy-policy-label': '用户隐私',
                    'title1': '揭示作者的洞察：深入思考触手可及。',
                    'title2': '更智能的总结：量身定制以满足您的需求。',
                    'title3': '与AI一起探索：您的问题，您的兴趣，得到恰当的回答。',
                    'title4': '获得重要的答案：没有幻觉，没有干扰，不再浪费时间。',
                    'description1': '发现顺畅研究的入口，轻松导航复杂信息和深入知识。',
                    'description2': '体验总结技术的前沿，提供简洁准确的总结，量身定制以满足您的需求。',
                    'description3': '通过AI驱动的搜索功能深入探索您的兴趣，连接您与与您兴趣相关的文章。',
                    'description4': '与我们专门的问答系统互动，该系统旨在通过分析内容提供准确的答案，确保您对询问获得知情的回应。',
                    'identified-article-label': '在您的文库中找到了之前上传相同的文章，请核实：',
                    'accept-label': '确认是这篇文章',
                    'continue-uploading-label': '作为新文章上传',
                    "zoom-in": "放大",
                    "zoom-out": "缩小",
                    "clear-highlights": "清空所有高亮",
                    "focus-mode": "聚焦模式",
                    "remove-from": "移除",
                    "add-to": "添加",
                    "my-reading-list": "阅读列表",
                    "my-reading-list-postfix": "我的阅读列表",
                    "math-explaination": "数学公式解析",
                    "support-center": "支持中心",
                    "get-summary-label": "获取本文摘要",
                    "all-qa-tooltip": "初始时，只显示与选定文章相关的问答环节。但启用此功能后，将可以访问所有会话。",
                    "generating-summary-label": "正在生成摘要。",
                    "middle-school": "初中",
                    "high-school": "高中",
                    "college": "大学",
                    "expert": "专家",
                    "others": "其他",
                    "level": "水平",
                    "words": '字数',
                    'generate-summary': '生成摘要',
                    'summary-lang-label': '中文',
                    'rename': '重命名',
                    'download': '下载',
                    'delete': '删除',
                    'copy': '复制',
                    "highlight": "高亮",
                    "clear-highlight": "清除高亮",
                    "add-notes": "添加到笔记",
                    "coming-soon": "即将推出",
                    'success-label': '成功',
                    'failed-label': '失败',
                    'copy-successful': '复制成功',
                    'copy-failed': '复制失败',
                    "inspiration-message": "开启灵感功能，Doenba 将根据您最近的查询和文章内容生成富有洞察力的建议，以鼓励更深入、更广泛的思考。",
                    'enter-msg-here': '请在此输入您的问题',
                    'no-conversation-history': '没有会话记录',
                    'search-articles-here': '在此搜索文章...',
                    'remove-article-label': '从阅读列表中移除文章',
                    'no-article-been-added': '还没有添加文章',
                    "processing-indicator-tooltip-label": "任务 RQA 处理中。感谢您的耐心等待。",
                    "processing-indicator-error": "系统当前繁忙。请稍后再试。",
                    'open': '打开',
                    'read-later': '稍后阅读',
                    'is-ready-postfix': '准备就绪',
                    "video-title-rqa": "阅读与回答",
                    "video-title-inspiration": "启发模式",
                    "video-title-tables-formulas": "表格和公式解析",
                    "video-title-organization": "资料管理",
                    'video-title-undergrad-math_demo': '本科数学演示',
                    'demo-videos': '视频演示',
                    'topic-highlighting': '主题问答高亮',
                    'highlighting-context': '问答相关原文高亮',
                    'custom-summary-description': '',
                    'rqa-description': '',
                    'topic-highlighting-description': '',
                    'highlighting-context-description': '',
                    'inspiration-description': '',
                    'choose-existing-article': '选择现有文章',
                    'case-study-label': '案例展示',
                    'in-action-label': 'Doenba.ai 助力学习',
                    'features-label': '特别功能',
                    'delete-conversation-label': '删除该对话',
                    'conversation-deleting-confirmation-message': '您确定要删除此对话消息吗？',
                    'confirm-label': '确认',
                    'message-deleting-label': '对话删除?',
                    'more-options-label': '更多选项',
                    'quotation-label': '引用',
                    'editor-previewmodel-selectlabeltip':'Once you select the template, we will sync the structure to your writing',
                    'editor-previewmodel-selectbtnlabel':'选择模板',
                    'editor-previewmodel-switchBtnTip':'没有更多模板',
                    'editor-editormodel-leftHeader':'Reference Article',
                    'editor-editormodel-leftHeaderRefOutline':'Structure',
                    'editor-editormodel-leftHeaderRefTone':'Tone',
                    'editor-editormodel-leftDeleteRef':'Are you sure you want to delete this {0}?',
                    'editor-editormodel-leftDeletePanel1':'Are you sure you want to delete',
                    'editor-editormodel-leftDeletePanel2':'in your structure?',
                    'editor-editormodel-leftDeletePanel3':'The content under this section will belongs to the Body.',
                    'editor-editormodel-leftBtnCancel':'cancel',
                    'editor-editormodel-leftBtnDelete':'delete',
                    'editor-editormodel-leftBtnConfirm':'Confirm',
                    'editor-editormodel-leftBtnSelect':'selete',
                    'editor-editormodel-leftBtnApply':'apply',
                    'editor-editormodel-leftTipUpload':'Drag to Upload',
                    'editor-editormodel-leftTipUploadFile':' Please upload pdf. words. txt. files',
                    'editor-editormodel-leftOverview':'Overview',
                    'editor-editormodel-leftStyle':'Style',
                    'editor-editormodel-leftExport':'Export',
                    'editor-editormodel-leftTutorials':'Tutorials',
                    'editor-editormodel-leftHelps':'Helps',
                    'editor-editormodel-treeAdd':'Add New',
                    'editor-editormodel-treeRename':'Rename',
                    'editor-editormodel-treeDelete':'Delete',
                    'editor-editormodel-treeInputCancel':'Cancel',
                    'editor-editormodel-treeInputSave':'Save',
                    'editor-editormodel-noSectionTip1':'Doenba Editor’s goal is not only to assist you in writing but also to foster a collaborative environment and ensure an efficient, streamlined writing process.',
                    'editor-editormodel-noSectionTip2.1':'Click the',
                    'editor-editormodel-noSectionTip2.2':'to begin creating your structure, and you can start writing.',
                    'editor-editormodel-midNewSubsection2':'Heading2',
                    'editor-editormodel-midNewSubsection3':'Heading3',
                    'editor-editormodel-midNewSubsection4':'Heading4',
                    'editor-editormodel-midNewSubsection5':'Heading5',
                    'editor-editormodel-midNewSubsection6':'Heading6',
                    'editor-editormodel-midNewParagraph':'Paragraph',
                    'editor-editormodel-midNewTable':'Table',
                    'editor-editormodel-rightLibrary':'Resources Library',
                    'editor-editormodel-rightLibraryRemove':'Remove',
                    'editor-editormodel-rightLibraryCite':'Cite',
                    'editor-editormodel-rightLibraryAdd':'Add',
                }
            }
        }
    })