import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
  Link as ChakraLink,
  useToast,
  useDisclosure,
  List,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ListItem,
  Input,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { PaperService } from "../services/paperService";
import { ClientError } from "../utils/clientError";
import { Paper, UpdatePaperRequest } from "../types/Writer/Paper";
import {
  OutlineNodeType,
  PaperOutline,
  PaperOutlineNode,
} from "../types/Writer/OutlineNode";
import { useTranslation } from "react-i18next";
import NodeTree from "../components/OutlineNodeTree/NodeTree";
import {
  formatString,
  getNodeRoot,
  getTreeNodes,
} from "../types/Writer/NodesFunc";
import Toolbox from "../components/PaperEditor/Toolbox";
import EditorModelRight from "./EditorModelRight";
import {
  LuHeading2,
  LuHeading3,
  LuHeading4,
  LuHeading5,
  LuHeading6,
  LuText,
} from "react-icons/lu";
import EditorContent from "../components/PaperEditor/EditorContent";
import { PiDotsThreeVerticalBold, PiDotsSixVerticalBold } from "react-icons/pi";

const EditorModel: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const listRef = useRef(null);
  //----------------------------------------------------
  const { paperId } = useParams();
  const [usePaperId, setUsePaperId] = useState<string>("");
  const [paper, setPaper] = useState<Paper>(null);
  const [sectionNode, setSectionNode] = useState<PaperOutlineNode>(undefined);
  const [nodeList, setNodeList] = useState<Array<PaperOutlineNode>>([]);
  const [paperName, setPaperName] = useState<string>("");
  const [outline, setOutline] = useState<PaperOutline>(undefined);
  const [refOutline, setRefOutline] = useState<string>("example1");
  const [refTone, setRefTone] = useState<string>("example123");
  const [referencePanelOpenStatus, setReferencePanelOpenStatus] =
    useState<boolean>(false);
  const {
    isOpen: isOpenDeleteConfirmPanel,
    onOpen: onOpenDeleteConfirmPanel,
    onClose: onCloseDeleteConfirmPanel,
  } = useDisclosure();
  const {
    isOpen: isReferenceUploadPanel,
    onOpen: onOpenReferenceUploadPanel,
    onClose: onCloseReferenceUploadPanel,
  } = useDisclosure();
  const {
    isOpen: isOpenTitleInputPanel,
    onOpen: onOpenTitleInputPanel,
    onClose: onCloseTitleInputPanel,
  } = useDisclosure();
  const [deleteTipStr, setDeleteTipStr] = useState<string>("");
  const [inputPanelPos, setInputPanelPos] = useState<[number, number]>([0, 0]);
  const [newTitle, setNewTitle] = useState<string>("");
  const [newTitleLabel, setNewTitleLabel] = useState<string>("");
  const [editNode, setEditNode] = useState<PaperOutlineNode>();
  const [nodeWords, setNodeWords] = useState<number>(0);
  const [totalWords, setTotalWords] = useState<number>(0);

  const updateOutline = async (newOutline: PaperOutline) => {
    if (newOutline == undefined) return;
    const updateRequest: UpdatePaperRequest = {
      name: paperName,
      outline: newOutline,
      authors: paper.authors,
      content: paper.content,
      abstract: paper.abstract,
      keywords: paper.keywords,
      draft: paper.draft,
      resources: paper.resources,
    };
    try {
      const newPaper = await PaperService.updatePaper(
        usePaperId,
        updateRequest
      );
      setOutline(newPaper?.outline);
      setPaperName(newPaper?.name);
      setPaper(newPaper);
      toast({
        title: "Document",
        description: "save",
        status: "success",
        duration: 1000,
      });
    } catch (error) {
      new ClientError(error).toast();
    }
  };

  const addNewNode = (
    node: PaperOutlineNode,
    index: number,
    type: OutlineNodeType,
    samelevel?: boolean
  ) => {
    const newNode: PaperOutlineNode = {
      id: outline.nextId,
      name: "new " + type,
      type: type,
      level:
        node.type == OutlineNodeType.Section && !samelevel
          ? node.level + 1
          : node.level,
    };
    if (node.children == undefined) node.children = [];
    if (node.type == "section" && !samelevel) {
      node.children.splice(0, 0, newNode);
    } else {
      let count = 0;
      for (let i = index - 1; i >= 0; i--) {
        if (nodeList[i].level == newNode.level) count += 1;
        if (
          nodeList[i].type == OutlineNodeType.Section &&
          nodeList[i].level < node.level
        ) {
          nodeList[i].children.splice(count + 1, 0, newNode);
          break;
        }
      }
    }
    return {
      nextId: newNode.id + 1,
      nodes: outline.nodes,
    };
  };

  const confirmDelete = (): PaperOutline => {
    for (let i = 0; i < nodeList.length; i++) {
      const index = nodeList[i].children?.indexOf(editNode);
      if (index > -1) {
        nodeList[i].children.splice(index, 1);
        return {
          nextId: outline.nextId,
          nodes: outline.nodes,
        };
      }
    }
    return undefined;
  };

  const getSectionButton = (
    node: PaperOutlineNode,
    btnClick: () => void,
    samelevel?: boolean
  ) => {
    const level =
      node.type === OutlineNodeType.Section && !samelevel
        ? node.level + 1
        : node.level;
    if (level > 6) return undefined;
    let icon = undefined;
    switch (level) {
      case 2:
        icon = <LuHeading2 />;
        break;
      case 3:
        icon = <LuHeading3 />;
        break;
      case 4:
        icon = <LuHeading4 />;
        break;
      case 5:
        icon = <LuHeading5 />;
        break;
      case 6:
        icon = <LuHeading6 />;
        break;
      default:
        break;
    }
    return (
      <MenuItem
        onClick={btnClick}
        _hover={{ bgColor: "#3898FF61" }}
        borderRadius={10}
      >
        {icon}
        <Text pl={"3px"} fontSize={14}>
          {t("editor-editormodel-midNewSubsection" + level)}
        </Text>
      </MenuItem>
    );
  };
  const getNodesWordsCount = (nodes: PaperOutlineNode[]) => {
    if (!nodes) return 0;
    let wordsCount = 0;
    nodes.forEach((node) => {
      if (!node) return;
      const str = node.type == "paragraph" ? node.content : node.name;
      if (str) {
        str.replace("\n", " ");
        wordsCount += str.split(" ").length;
      }
    });
    return wordsCount;
  };
  //----------------------------------------------------
  const leftBarWidth: string = "270px";
  //----------------------------------------------------
  useEffect(() => {
    async function fetchPaper() {
      try {
        const newPaper = await PaperService.getPaperById(usePaperId);
        setPaper(newPaper);
        setOutline(newPaper?.outline);
        setPaperName(newPaper?.name);
        setRefOutline(newPaper?.resources[0]?.pdf_link);
        setRefTone(newPaper?.resources[1]?.pdf_link);
      } catch (error) {
        new ClientError(error).toast();
      }
    }
    if (!usePaperId) return;
    fetchPaper();
  }, [usePaperId]);

  useEffect(() => {
    if (!outline) return;
    setTotalWords(getNodesWordsCount(getTreeNodes(outline?.nodes)));
  }, [outline]);

  useEffect(() => {
    if (!outline) return;
    setNodeList(getTreeNodes([getNodeRoot(outline?.nodes, sectionNode)]));
  }, [sectionNode]);

  useEffect(() => {
    if (!nodeList) return;
    setNodeWords(getNodesWordsCount(nodeList));
  }, [nodeList]);

  useEffect(() => {
    if (!paperId) return;
    setUsePaperId(paperId);
  }, [paperId]);

  //-------------------------------------------------------
  return (
    <>
      <HStack
        spacing={"17px"}
        h={"100%"}
        w={"100%"}
        style={{ overflow: "auto" }}
      >
        <Flex
          bg={"gray.100"}
          h={"100%"}
          flexDir={"column"}
          w={leftBarWidth}
          justifyContent={"space-between"}
          boxShadow={"md"}
          position={"relative"}
          transition={"all 0.5s ease"}
          bgColor={"#FBFCFF"}
        >
          <Flex flexDir={"column"}>
            <Center p={10}>
              <Box
                w={"144px"}
                h={"40px"}
                onClick={() => navigate("/editor")}
                cursor={"pointer"}
              >
                <Image
                  objectFit="cover"
                  src={"/doenba-logo-blue.png"}
                  alt="Doenba"
                />
              </Box>
            </Center>
            <Flex
              flexDir={"column"}
              px={5}
              py={2}
              borderBottom={"1px solid #ccc"}
            >
              <Button
                width={"230px"}
                pl={5}
                pr={5}
                w={"full"}
                bgColor={referencePanelOpenStatus ? "#ADD2FC" : "transparent"}
                leftIcon={
                  <Image
                    w={"20px"}
                    objectFit="cover"
                    src="/editor/document-like.png"
                    alt="Doenba"
                  />
                }
                _hover={{ color: "#ADD2FC" }}
                rightIcon={
                  <Image
                    w={"20px"}
                    objectFit="cover"
                    src="/arrow-down.png"
                    alt="Doenba"
                  />
                }
                onClick={() =>
                  setReferencePanelOpenStatus(!referencePanelOpenStatus)
                }
              >
                <Text m={1} as={"b"} color={"black"} fontSize={"20px"}>
                  {t("editor-editormodel-leftHeader")}
                </Text>
              </Button>
              {referencePanelOpenStatus && (
                <Box
                  px={2}
                  py={3}
                  height={"133px"}
                  mt={-1}
                  borderRight={"1px solid #ccc"}
                  borderLeft={"1px solid #ccc"}
                  borderBottom={"1px solid #ccc"}
                  borderRadius={"0 0 10px 10px"}
                  transition={"all 0.5s ease"}
                >
                  <Flex flexDir={"column"} justifyContent={"space-between"}>
                    <VStack flexDir={"row"} spacing={"10px"}>
                      <Image
                        w={"24px"}
                        objectFit="cover"
                        src="/editor/text.png"
                        alt="no draft"
                      />
                      <Text as={"b"}>
                        {t("editor-editormodel-leftHeaderRefOutline")}
                      </Text>
                    </VStack>
                    <Flex flexDir={"row"}>
                      <Center>
                        <Text ml={"30px"} width={"120px"}>
                          {refOutline}
                        </Text>
                      </Center>
                      <Center>
                        <IconButton
                          size={"sm"}
                          aria-label="replace"
                          bgColor={"whiteAlpha.50"}
                          icon={
                            <Image
                              w={"12px"}
                              objectFit="cover"
                              src="/editor/Property 33.png"
                              alt="no draft"
                            />
                          }
                          onClick={() => {
                            setDeleteTipStr("Referenced Structure");
                            onOpenReferenceUploadPanel();
                          }}
                        />
                      </Center>
                      <Center>
                        <IconButton
                          size={"sm"}
                          aria-label="remove"
                          bgColor={"whiteAlpha.50"}
                          icon={
                            <Image
                              w={"16px"}
                              objectFit="cover"
                              src="/editor/delete3.png"
                              alt="no draft"
                            />
                          }
                          onClick={() => {
                            setDeleteTipStr("Referenced Structure");
                            onOpenDeleteConfirmPanel();
                          }}
                        />
                      </Center>
                    </Flex>
                    <VStack flexDir={"row"} spacing={"10px"}>
                      <Image
                        w={"24px"}
                        objectFit="cover"
                        src="/editor/text.png"
                        alt="no draft"
                      />
                      <Text as={"b"}>
                        {t("editor-editormodel-leftHeaderRefTone")}
                      </Text>
                    </VStack>
                    <Flex flexDir={"row"}>
                      <Center>
                        <Text ml={"30px"} width={"120px"}>
                          {refTone}
                        </Text>
                      </Center>
                      <Center>
                        <IconButton
                          size={"sm"}
                          aria-label="replace"
                          bgColor={"whiteAlpha.50"}
                          icon={
                            <Image
                              w={"12px"}
                              objectFit="cover"
                              src="/editor/Property 33.png"
                              alt="no draft"
                            />
                          }
                          onClick={() => {
                            setDeleteTipStr("Referenced Tone");
                            onOpenReferenceUploadPanel();
                          }}
                        />
                      </Center>
                      <Center>
                        <IconButton
                          size={"sm"}
                          aria-label="remove"
                          bgColor={"whiteAlpha.50"}
                          icon={
                            <Image
                              w={"16px"}
                              objectFit="cover"
                              src="/editor/delete3.png"
                              alt="no draft"
                            />
                          }
                          onClick={() => {
                            setDeleteTipStr("Referenced Tone");
                            onOpenDeleteConfirmPanel();
                          }}
                        />
                      </Center>
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Flex>
            <Box
              mt={5}
              h={`calc(100vh - ${
                referencePanelOpenStatus ? "502px" : "373px"
              })`}
            >
              {outline && (
                <NodeTree
                  outline={outline}
                  updateOutline={updateOutline}
                  selectSection={sectionNode}
                  setSelectSection={setSectionNode}
                  height={`calc(100vh - ${
                    referencePanelOpenStatus ? "502px" : "373px"
                  })`}
                />
              )}
            </Box>
            <Button
              mt={3}
              w={"full"}
              leftIcon={
                <Image
                  w={"20px"}
                  objectFit="cover"
                  src="/editor/overview.png"
                  alt="no draft"
                />
              }
              bgColor={"#FBFCFF"}
              fontSize={18}
            >
              {t("editor-editormodel-leftOverview")}
            </Button>
            <Button
              bgColor={"#FBFCFF"}
              mt={3}
              w={"full"}
              fontSize={"18px"}
              leftIcon={
                <Image
                  w={"20px"}
                  objectFit="cover"
                  src="/editor/Export2.png"
                  alt="no draft"
                />
              }
            >
              {t("editor-editormodel-leftExport")}
            </Button>
            <Center mt={7}>
              <ChakraLink mb={5} variant="underline">
                {t("editor-editormodel-leftTutorials")}
              </ChakraLink>
            </Center>
          </Flex>
        </Flex>
        <Flex
          display={"flex"}
          flexDir={"column"}
          flex={"1 auto"}
          h={"100%"}
          overflow={"hidden"}
          paddingTop={3}
        >
          <Text h={"100px"} as={"b"} fontSize={39}>
            {paperName}
          </Text>
          <Toolbox onApplyStyle={undefined} />
          <Box minH={`calc(100% - 180px)`}>
            {sectionNode == undefined ? (
              <Box bgColor={"blackAlpha.300"} w={"100%"} h={`100%`}>
                <Center h={"100%"} w={"100%"}>
                  <Flex
                    direction="column"
                    bgColor={"white"}
                    borderRadius={"10px"}
                    h={"304px"}
                    w={"512px"}
                    p={37}
                  >
                    <Text color={"#8E9ABC"} fontSize={"11px"}>
                      {t("editor-editormodel-noSectionTip1")}
                    </Text>
                    <Spacer />
                    <Flex>
                      <Spacer />
                      <Text color={"#333333"} fontSize={"11px"} mt={3}>
                        {t("editor-editormodel-noSectionTip2.1")}
                      </Text>
                      <Image
                        w={"40px"}
                        objectFit="cover"
                        src="/editor/add-square.png"
                        alt="Doenba"
                      />
                      <Text color={"#333333"} fontSize={"11px"} mt={3}>
                        {t("editor-editormodel-noSectionTip2.2")}
                      </Text>
                      <Spacer />
                    </Flex>
                    <Spacer />

                    <Box
                      marginLeft={"100px"}
                      w={"50%"}
                      h={"50px"}
                      onClick={() => navigate("/editor")}
                      cursor={"pointer"}
                    >
                      <Image
                        objectFit="cover"
                        src={"/doenba-logo-blue.png"}
                        alt="Doenba"
                      />
                    </Box>
                    <Spacer />
                  </Flex>
                </Center>
              </Box>
            ) : (
              <Flex
                flexDir={"column"}
                height={`100%`}
                width={"100%"}
                overflowY={"hidden"}
              >
                <List
                  ref={listRef}
                  height={`100%`}
                  borderRadius={"10px"}
                  overflowY={"auto"}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#9EC3E8",
                      borderRadius: "2px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#2b6cb0",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#C9D8E7",
                    },
                  }}
                >
                  {nodeList?.map((child, index) =>
                    child == undefined ? undefined : (
                      <ListItem
                        flex={"column"}
                        w={"100%"}
                        key={child.id.toString() + child.name}
                      >
                        <HStack
                          alignItems={"flex-start"}
                          pl={(56 * (child.level - 1)).toString() + "px"}
                        >
                          <Menu matchWidth={true}>
                            <MenuButton
                              as={IconButton}
                              mt={8}
                              size={"xs"}
                              bgColor={"transparent"}
                              _hover={{ bgColor: "transparent" }}
                              icon={
                                <Image
                                  w={"16px"}
                                  objectFit="cover"
                                  src="/editor/add.png"
                                  alt="Doenba"
                                />
                              }
                            />
                            <MenuList
                              border={"1px solid #cccccc"}
                              minWidth={109}
                            >
                              <MenuItem
                                _hover={{ bgColor: "#3898FF61" }}
                                borderRadius={10}
                                onClick={() => {
                                  updateOutline(
                                    addNewNode(
                                      child,
                                      index,
                                      OutlineNodeType.Paragraph
                                    )
                                  );
                                }}
                              >
                                <LuText />
                                <Text pl={"3px"} fontSize={14}>
                                  {t("editor-editormodel-midNewParagraph")}
                                </Text>
                              </MenuItem>
                              {child.level != 1 &&
                                child.type == "section" &&
                                getSectionButton(
                                  child,
                                  () => {
                                    updateOutline(
                                      addNewNode(
                                        child,
                                        index,
                                        OutlineNodeType.Section,
                                        true
                                      )
                                    );
                                  },
                                  true
                                )}
                              {getSectionButton(child, () => {
                                updateOutline(
                                  addNewNode(
                                    child,
                                    index,
                                    OutlineNodeType.Section
                                  )
                                );
                              })}
                            </MenuList>
                          </Menu>
                          <HStack
                            mt={8}
                            alignItems={"flex-start"}
                            draggable
                            width={
                              child.type == "paragraph" ? "100%" : undefined
                            }
                          >
                            {child.level != 1 && (
                              <IconButton
                                size={"xs"}
                                color={"#8E9ABC"}
                                bgColor={"transparent"}
                                _hover={{ borderRadius: "15px" }}
                                icon={
                                  <PiDotsSixVerticalBold
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                }
                                aria-label={""}
                              />
                            )}
                            {child.type == "paragraph" ? (
                              <EditorContent
                                content={child.content}
                                updateNode={(value) => {
                                  if (value != child.content) {
                                    child.content = value;
                                    updateOutline(outline);
                                  }
                                }}
                              />
                            ) : (
                              <Text
                                bgColor={"transparent"}
                                _hover={{ bgColor: "transparent" }}
                                textColor={
                                  child.level == 1 ? "#1D8AFF" : "#8E9ABC"
                                }
                                fontSize={"16px"}
                                fontWeight={"bold"}
                              >
                                {child.name}
                              </Text>
                            )}
                          </HStack>
                          {child.type != "paragraph" &&
                            (child.level == 1 ? (
                              <IconButton
                                mt={8}
                                ml={3}
                                size={"xs"}
                                bgColor={"#1D8AFF"}
                                _hover={{ bgColor: "#1D8AFF" }}
                                icon={
                                  <Image
                                    w={"24px"}
                                    objectFit="cover"
                                    src="/editor/edit2.png"
                                    alt="no draft"
                                  />
                                }
                                onClick={(e) => {
                                  const { x, y } = (
                                    e.target as HTMLDivElement
                                  ).getBoundingClientRect();
                                  setInputPanelPos([y - 60, x - 810]);
                                  onOpenTitleInputPanel();
                                  setNewTitle(child.name);
                                  setNewTitleLabel(child.label);
                                  setEditNode(child);
                                }}
                                aria-label={""}
                              />
                            ) : (
                              <Menu matchWidth={true} offset={[27, -24]}>
                                <MenuButton
                                  mt={8}
                                  ml={3}
                                  color={"#8E9ABC"}
                                  as={IconButton}
                                  bgColor={"transparent"}
                                  _hover={{ bgColor: "transparent" }}
                                  size={"xs"}
                                  icon={
                                    <PiDotsThreeVerticalBold
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                />
                                <MenuList
                                  border={"1px solid #cccccc"}
                                  minWidth={93}
                                  bgSize={"xs"}
                                  maxBlockSize={"100px"}
                                  backgroundSize={"sm"}
                                >
                                  <MenuItem
                                    as={Button}
                                    _hover={{ bgColor: "#3898FF61" }}
                                    borderRadius={10}
                                    justifyContent="flex-start"
                                    onClick={(e) => {
                                      const { x, y } = (
                                        e.target as HTMLDivElement
                                      ).getBoundingClientRect();
                                      setInputPanelPos([y - 70, x - 835]);
                                      onOpenTitleInputPanel();
                                      setNewTitle(child.name);
                                      setNewTitleLabel(child.label);
                                      setEditNode(child);
                                    }}
                                    textAlign="left"
                                    pl={0.5}
                                    leftIcon={
                                      <Image
                                        sizes="xs"
                                        w={"24px"}
                                        objectFit="cover"
                                        src="/editor/edit2.png"
                                        alt="no draft"
                                      />
                                    }
                                    fontSize={14}
                                    textColor={"black"}
                                  >
                                    {t("editor-editormodel-treeRename")}
                                  </MenuItem>
                                  <MenuItem
                                    as={Button}
                                    justifyContent="flex-start"
                                    textAlign="left"
                                    pl={0.5}
                                    onClick={() => {
                                      onOpenDeleteConfirmPanel();
                                      setEditNode(child);
                                      setDeleteTipStr(child.name);
                                    }}
                                    _hover={{ bgColor: "#3898FF61" }}
                                    borderRadius={10}
                                    leftIcon={
                                      <Image
                                        sizes="xs"
                                        w={"24px"}
                                        objectFit="cover"
                                        src="/editor/edit2.png"
                                        alt="no draft"
                                      />
                                    }
                                    fontSize={14}
                                    textColor={"black"}
                                  >
                                    {t("editor-editormodel-treeDelete")}
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            ))}
                        </HStack>
                      </ListItem>
                    )
                  )}
                </List>
                <Center>
                  <Center
                    border={"1px solid #ccc"}
                    borderRadius={"10px"}
                    width={"143px"}
                    height={"25px"}
                  >
                    {formatString("{0}/{1} words", nodeWords, totalWords)}
                  </Center>
                </Center>
              </Flex>
            )}
          </Box>
        </Flex>
        <EditorModelRight outline={outline} />
      </HStack>
      <Modal
        isOpen={isOpenDeleteConfirmPanel}
        onClose={onCloseDeleteConfirmPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay
          background={"blackAlpha.100"}
          backdropFilter={"blur(1px)"}
        />
        <ModalContent
          width={551}
          height={345}
          top={200}
          border={"1px solid #ccc"}
        >
          <ModalBody p={5}>
            <Flex flexDir={"column"}>
              <Center mt={5}>
                <Image
                  w={"100px"}
                  objectFit="cover"
                  src="/editor/delete2.png"
                  alt="Doenba"
                />
              </Center>
              <Center mt={30}>
                <Text textColor={"black"}>
                  {t("editor-editormodel-leftDeletePanel1")}
                  <span style={{ color: "#1D8AFF" }}>{deleteTipStr}</span>
                  {t("editor-editormodel-leftDeletePanel2")}
                </Text>
              </Center>
              <Center>
                <Text textColor={"black"}>
                  {t("editor-editormodel-leftDeletePanel3")}
                </Text>
              </Center>
              <HStack p={10} spacing={150}>
                <Button
                  bgColor={"#9399AB"}
                  width={"139px"}
                  height={"48px"}
                  textColor={"white"}
                  _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                  onClick={() => {
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("editor-editormodel-leftBtnCancel")}
                </Button>
                <Button
                  bgColor={"#9399AB"}
                  width={"139px"}
                  height={"48px"}
                  textColor={"white"}
                  _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                  onClick={() => {
                    updateOutline(confirmDelete());
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("editor-editormodel-leftBtnDelete")}
                </Button>
              </HStack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isReferenceUploadPanel}
        onClose={onCloseReferenceUploadPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay
          background={"blackAlpha.100"}
          backdropFilter={"blur(1px)"}
        />
        <ModalContent
          width={730}
          height={404}
          top={200}
          border={"1px solid #ccc"}
        >
          <ModalBody p={5}>
            <Flex flexDir={"column"}>
              <Text as="b" fontSize={"16px"}>
                {t("editor-editormodel-leftHeader")}
              </Text>
              <Text textColor={"#8E9ABC"}>
                {t("editor-editormodel-leftUploadRef")}
              </Text>
              <Center m={5}>
                <Flex
                  width={"316px"}
                  height={"192px"}
                  flexDir={"column"}
                  border={"1px solid #ccc"}
                >
                  <Center mt={3}>
                    <Image
                      w={"40px"}
                      objectFit="cover"
                      src="/editor/export.png"
                      alt="Doenba"
                    />
                  </Center>
                  <Center>
                    <Text as="b" textColor={"#8E9ABC"}>
                      {t("editor-editormodel-leftTipUpload")}
                    </Text>
                  </Center>
                  <Center>
                    <Text textColor={"#8E9ABC"}>
                      {t("editor-editormodel-leftTipUploadFile")}
                    </Text>
                  </Center>
                  <Center>
                    <Button
                      m={3}
                      w="100px"
                      h="34px"
                      bgColor={"#1D8AFF"}
                      textColor={"white"}
                      _hover={{ color: "#1D8AFF", textColor: "white" }}
                    >
                      {t("editor-editormodel-leftBtnSelect")}
                    </Button>
                  </Center>
                </Flex>
              </Center>
              <Button
                variant="outline"
                ml={550}
                mt={3}
                w="139px"
                h="48px"
                color={"#1D8AFF"}
                borderColor={"#1D8AFF"}
                onClick={() => {
                  setRefOutline("111");
                  setRefTone("112");
                  onCloseReferenceUploadPanel();
                }}
              >
                {t("editor-editormodel-leftBtnApply")}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenTitleInputPanel}
        onClose={onCloseTitleInputPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay background="transparent" />
        <ModalContent
          width={373}
          height={140}
          top={inputPanelPos[0]}
          left={inputPanelPos[1]}
          border={"1px solid #ccc"}
        >
          <ModalBody p={1}>
            <VStack p={2}>
              <Flex width={"100%"}>
                <Text
                  width={"60px"}
                  fontSize={16}
                  align={"left"}
                  as="b"
                  mt={2}
                  mr={2}
                >
                  Title:
                </Text>
                <Input
                  mt={2}
                  width={"283px"}
                  height={"27px"}
                  placeholder={"New Section New Section"}
                  defaultValue={newTitle}
                  borderColor={newTitle.length > 0 ? undefined : "red.300"}
                  onBlur={(e) => setNewTitle(e.target.value)}
                />
              </Flex>
              <Flex width={"100%"}>
                <Text
                  width={"60px"}
                  fontSize={16}
                  align={"left"}
                  as="b"
                  mt={2}
                  mr={2}
                >
                  Label:
                </Text>
                <Input
                  mt={2}
                  width={"283px"}
                  height={"27px"}
                  placeholder={"Use in Latex"}
                  defaultValue={newTitleLabel}
                  onBlur={(e) => setNewTitleLabel(e.target.value)}
                />
              </Flex>
              <HStack pt={1} spacing={170}>
                <Button
                  border={"3px solid #1D8AFF"}
                  width={"70px"}
                  height={"25px"}
                  fontSize={14}
                  textColor={"#1D8AFF"}
                  onClick={() => {
                    onCloseTitleInputPanel();
                  }}
                >
                  {t("editor-editormodel-treeInputCancel")}
                </Button>
                <Button
                  border={"3px solid #1D8AFF"}
                  width={"70px"}
                  height={"25px"}
                  fontSize={14}
                  textColor={"#1D8AFF"}
                  onClick={() => {
                    if (newTitle.length > 0) {
                      onCloseTitleInputPanel();
                      editNode.name = newTitle;
                      editNode.label = newTitleLabel;
                      updateOutline(outline);
                    }
                  }}
                >
                  {t("editor-editormodel-treeInputSave")}
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditorModel;
